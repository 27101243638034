import './MyCoursesAndLpPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getMyCLPById, getUUIDMyCLPActivated } from 'store/app/app.selectors';
import { resetBreadcrumb, updateBreadcrumbItems } from 'store/breadcrumb/breadcrumb.slice';
import { PAGES_INFO, PageInfoEnum } from 'routes/pageSettings';
import { AteMyCoursesAndLp } from 'components/widgets/myCoursesAndLp/AteMyCoursesAndLp';
import { Spinner } from '@fluentui/react-components';

export default function MyCoursesAndLpPage() {
    const dispatch = useDispatch();
    const uuidActivated = useSelector(getUUIDMyCLPActivated);
    const settings = useSelector(state => getMyCLPById(state, uuidActivated));

    useEffect(() => {
        if (uuidActivated) {
            dispatch(
                updateBreadcrumbItems(
                    {
                        showFilters: true,
                        items: [
                            PAGES_INFO[PageInfoEnum.DASHBOARD],
                            PAGES_INFO[PageInfoEnum.MY_COURSES_AND_LP],
                        ]
                    }),
            );
        }
        return () => { dispatch(resetBreadcrumb()) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidActivated])

    return (
        <div className="my-courses-and-lp-page">
            <div className="ui-row">
                {uuidActivated && settings ?
                    <AteMyCoursesAndLp
                        UUID={settings.UUID}
                        title={settings.title}
                        description={settings.description}
                        course_type={settings.course_type}
                        course_status={settings.course_status}
                        hide_courses_in_lp={settings.hide_courses_in_lp}
                        view={settings.view}
                        isDashboardPage={false}
                    /> :
                    <Spinner />
                }
            </div>
        </div>
    );
}
