import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getOriginalPath } from 'store/app/app.selectors';
import { getIsLogged, getUserNeedToAcceptPolicy, getUserNeedToAcceptTermsAndConditions } from 'store/auth/auth.selectors';
import { PageInfoEnum, PAGES_INFO } from './pageSettings';
import { IRoute } from './routes.config';

export default function PublicRoute({ component: Component, restricted, ...rest }: Omit<IRoute, 'authRequired'>) {
    const isLogged = useSelector(getIsLogged);
    const originalPath = useSelector(getOriginalPath);
    const userNeedToAcceptPolicy = useSelector(getUserNeedToAcceptPolicy);
    const userNeedToAcceptTermsAndConditions = useSelector(getUserNeedToAcceptTermsAndConditions);

    return (
        <Route {...rest} render={props => (
            restricted && isLogged ?
                userNeedToAcceptPolicy || userNeedToAcceptTermsAndConditions ?
                    <Redirect
                        to={PAGES_INFO[PageInfoEnum.POLICIES].path}
                    /> :
                    <Redirect
                        to={originalPath}
                    /> :
                <Component
                    {...props}
                />
        )
        } />
    );
}
