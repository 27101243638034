import { combineReducers } from 'redux';
import app from './app/app.slice';
import msContext from './msContext/msContext.slice';
import bootstrap from './bootstrap/bootstrap.slice';
import session from './session/session.slice';
import auth from './auth/auth.slice';
import breadcrumb from './breadcrumb/breadcrumb.slice';
import translations from './translations/translations.slice';

const rootReducer = combineReducers({
    app,
    msContext,
    bootstrap,
    session,
    auth,
    breadcrumb,
    translations
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
