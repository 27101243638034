import { getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import { epicMiddleware, rootEpic } from './middleware';
import rootReducer from './reducers';

const store = configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false,   // need to remove this middleware in order to use class models for store initial value
            thunk: false
        }),
        epicMiddleware
    ]
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;

export default store;
