import './AteIcon.scss';
import { IAteIcon } from 'components/shared/icon/interfaces/icon.interfaces';
import React from 'react';
import { DCB_UI_SVG_ICONS } from 'components/shared/icon/helpers/icons';

export function AteIcon(props: IAteIcon) {
    return (
        <div className={`ate-icon ate-icon-size-${props.size}`}>
            <span
                className={`color-${props.color}`}
                style={{
                    fill: props.customColor
                }}>
                <div dangerouslySetInnerHTML={{ __html: DCB_UI_SVG_ICONS[props.name] }} />
            </span>
        </div>
    );
}
