import AteStaticCourseListGroupedView from "./groupedView/AteStaticCourseListGroupedView";
import { IAteStaticCourseListWidget, SCLWidgetViewOptions } from "./interfaces/staticCourseList.interface";
import AteStaticCourseListUngroupedView from "./ungroupedView/AteStaticCourseListUngroupedView";

export default function AteStaticCourseList(props: IAteStaticCourseListWidget) {
    const isGroupedView = props.widget_view === SCLWidgetViewOptions.GROUPED;

    return (
        isGroupedView ? <AteStaticCourseListGroupedView {...props} /> : <AteStaticCourseListUngroupedView {...props} />
    )
}
