import { IWidgetTranslationLabelSettings } from "intefaces/commons.inteface";

export enum SCLWidgetViewOptions {
    GROUPED = 'grouped',
    UNGROUPED = 'ungrouped'
}

export enum SCLCourseViewOptions {
    CARDS = 'cards',
    LIST = 'list'
}

export interface IAteStaticCourseListWidget {
    course_ids: number[],
    widget_view: SCLWidgetViewOptions,
    courses_view: SCLCourseViewOptions,
    title?: IWidgetTranslationLabelSettings,
    subtitle?: IWidgetTranslationLabelSettings,
    icon_colour?: string,
    background_colour?: string,
    min_height?: number,
    /** USED ONLY IN FE TO IDENTIFY IF WIDGET IS USED IN SCL PAGE */
    usedInSCLPage?: boolean;
}

export const FROM_SCL_PAGE_QUERY_PARAM = 'from_scl_page';
