export default class AteLocalizable {
    module: string;
    phrase: string;
    variables: { [key: string]: string | number; };

    constructor(
        module: string,
        phrase: string,
        variables: { [key: string]: string | number; } = {},
    ) {
        this.module = module;
        this.phrase = phrase;
        this.variables = variables;
    }
}
