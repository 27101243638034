
import { teamsLightTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-components';

export const customTeamsLightTheme = {
    ...teamsLightTheme,
    fontFamilyBase: 'var(--ui-typography-family)',
}

export const customTeamsDarkTheme = {
    ...teamsDarkTheme,
    fontFamilyBase: 'var(--ui-typography-family)'
}

export const customTeamsHighContrastTheme = {
    ...teamsHighContrastTheme,
    fontFamilyBase: 'var(--ui-typography-family)'
}

