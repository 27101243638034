import { AuthStore } from 'intefaces/Auth';
import { IBootstrap } from 'intefaces/Bootstrap';
import { ISession } from 'intefaces/Session';
import { RootState } from './reducers';
import { app } from '@microsoft/teams-js';
import { IAteBreadcrumbSettings } from 'components/shared/breadcrumb/interfaces/breadcrumb.interface';

export const getMsContextStore = (state: RootState): app.Context => state.msContext;
export const getBootstrapStore = (state: RootState): IBootstrap => state.bootstrap;
export const getSessionStore = (state: RootState): ISession => state.session;
export const getAuthStore = (state: RootState): AuthStore => state.auth;
export const getBreadcrumb = (state: RootState): IAteBreadcrumbSettings => state.breadcrumb;
