import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getHttpClient } from './HttpClient';
import { IErisCheckTenantResponse, IErisJwtTokenResponse } from 'intefaces/eris.interface';

/**
 * This method retrieve main info stored on AWS DynamoDB about current tenant.
 * - Retrieve Url platform associated to current tenant
 * - Check if course sharing is enabled for the current tenant
 * - For Current User return an Access Token if it was cached previously
 * @param azureAccessToken
 * @returns
 */
export const getTenantInfo = (azureAccessToken: string): Observable<IErisCheckTenantResponse> => {
    return from(getHttpClient({ accessToken: azureAccessToken }, true).get<IErisCheckTenantResponse>(`/tenants/me`))
        .pipe(map(res => res.data));
};

/**
 * This method retrieve a JWT (or a Bearer Token if available) for the current user
 * @param azureAccessToken
 * @param ignoreAccessTokenCache
 * @returns
 */
export const getLMSTokenViaEris = (azureAccessToken: string, ignoreAccessTokenCache: boolean = false): Observable<IErisJwtTokenResponse> => {
    return from(getHttpClient({ accessToken: azureAccessToken }, true).get<IErisJwtTokenResponse>(`/tenants/me/token`,
        { params: { ignore_cache: ignoreAccessTokenCache } }
    )).pipe(map(res => res.data));
};

/**
 * Save on AWS DynamoDB a Bearer Token for the current user
 * @param azureAccessToken
 * @param accessTokenToCache
 * @returns
 */
export const setCachedAccessToken = (azureAccessToken: string, accessTokenToCache: string, lmsUserId: number): Observable<void> => {
    return from(getHttpClient({ accessToken: azureAccessToken }, true).post<void>(`/tenants/me/token`, { access_token: accessTokenToCache, user_id: lmsUserId }))
        .pipe(map(res => res.data));
};
