import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Divider } from '@fluentui/react-components';
import { StyledText, Segment } from '@fluentui/react-migration-v0-v9';
import { FilterRegular } from '@fluentui/react-icons';
import './AteDatabrowserDropdownFilter.scss';
import AteButtonRaised from "components/shared/buttons/button-raised/AteButtonRaised";
import { Subject } from "rxjs";
import { IAteDatabrowserDropdownFilter, IAteDatabrowserSingleFilter } from "components/shared/databrowser/interfaces/databrowser.interface";
import { isClickingOutsideElement } from "helpers/commons.helpers";
import { DICTIONARY } from "dictionary";
import { AteLocalize } from "services/translations/AteLocalize";

export default function AteDatabrowserDropdownFilter(props: IAteDatabrowserDropdownFilter) {
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [filters, setFilters] = useState(props.filters);
    const filtersActive = new Subject<IAteDatabrowserSingleFilter[]>();
    const firstUpdate = useRef(true);
    const panelRef = useRef(null);

    isClickingOutsideElement(panelRef)
        .subscribe(() => setDropdownOpened(false));

    filtersActive
        .asObservable()
        .subscribe(filters => {
            setDropdownOpened(false);
            props.onFiltersChange(filters);
        })

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            filtersActive.next(getActiveFilters());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleChangeFilter = (filter: IAteDatabrowserSingleFilter, filterIndex: number) => {
        const items = filters.map(item => Object.assign({}, item));
        const itemChanged = items.find((f, index) => f.value === filter.value && index === filterIndex);

        if (itemChanged) {
            itemChanged.isActive = !itemChanged.isActive;
        }

        setFilters(items);
    }

    const resetAllFilter = () => {
        const items = filters.map(item => Object.assign({}, { ...item, isActive: false }));
        setFilters(items);
    }

    const getActiveFilters = () => {
        return filters.filter(filter => filter.isActive === true);
    }

    const getLabel = (): string => {
        let label = '';
        const activeFiltersCount = getActiveFilters().length;

        if (!props.isStretchMode) {
            label += AteLocalize.getLocalizedLabel(DICTIONARY.filter);
        }

        if (activeFiltersCount) {
            label += ` (${activeFiltersCount})`;
        }

        return label;
    }

    return (
        <>
            <div
                className={`databrowser-filters-panel ${dropdownOpened ? 'active' : ''}`}
                ref={panelRef}
            >
                <div className="button-container">
                    <AteButtonRaised
                        isActive={dropdownOpened}
                        icon={<FilterRegular />}
                        content={getLabel()}
                        iconPosition="before"
                        onClick={() => { setDropdownOpened(!dropdownOpened) }}
                    />
                </div>

                <Segment
                    className="panel-container">
                    <div
                        className="header">
                        <StyledText
                            weight="semibold"
                            size="medium"
                        >
                            {AteLocalize.getLocalizedLabel(DICTIONARY.filter)}
                        </StyledText>
                        <span
                            className="clear">
                            <StyledText
                                size="medium"
                                onClick={() => resetAllFilter()}
                            >
                                {AteLocalize.getLocalizedLabel(DICTIONARY.clear)}
                            </StyledText>
                        </span>
                    </div>

                    <Divider />

                    <div
                        className="filter-list">
                        {filters.map((filter, index) => (
                            <div
                                className="single-filter"
                                key={index}>
                                <Checkbox
                                    className="checkbox-filter"
                                    label={AteLocalize.getLocalizedLabel(filter.label)}
                                    checked={filter.isActive ?? false}
                                    labelPosition="before"
                                    onChange={() => handleChangeFilter(filter, index)}
                                />
                            </div>
                        ))}
                    </div>
                </Segment>
            </div>
        </>
    )
}
