import { AuthErrorEnum, AuthStore, IDCBToken } from 'intefaces/Auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

const authSliceInitialState: AuthStore = {
    dcbAccessToken: {
        token: ''
    },
    isLogged: false,
    baseUrl: '',
    userNeedToAcceptPolicy: false,
    userNeedToAcceptTermsAndConditions: false,
};


const authSlice = createSlice({
    name: 'auth',
    initialState: authSliceInitialState,
    reducers: {
        setDcbAccessToken(state, action: PayloadAction<IDCBToken>) {
            action.payload.createdAt = Date.now();

            return {
                ...state,
                dcbAccessToken: action.payload,
            };
        },
        setIsLogged(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isLogged: action.payload,
            };
        },
        setBaseUrl(state, action: PayloadAction<string>) {
            return {
                ...state,
                baseUrl: action.payload,
            };
        },
        updateUserNeedToAcceptPolicy(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userNeedToAcceptPolicy: action.payload
            }
        },
        updateUserNeedToAcceptTermsAndConditions(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userNeedToAcceptTermsAndConditions: action.payload
            }
        },
        updateAuthenticationError(state, action: PayloadAction<AuthErrorEnum>) {
            return {
                ...state,
                authError: action.payload
            }
        },
        resetAuthenticationError(state) {
            const newState = _.cloneDeep(state);
            delete newState.authError;

            return newState;
        }
    },
});

export const {
    setDcbAccessToken,
    setIsLogged,
    setBaseUrl,
    updateUserNeedToAcceptPolicy,
    updateUserNeedToAcceptTermsAndConditions,
    updateAuthenticationError,
    resetAuthenticationError
} = authSlice.actions;

export default authSlice.reducer;
