import React from 'react';
import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';
import './AteCustomContentBox.scss';
import { useSelector } from 'react-redux';
import { getWidgetTranslatedLabel } from 'helpers/translations/translations.helpers';
import { HorizontalAlignValueToFlexEnum, VerticalAlignValueToFlexEnum } from 'intefaces/commons.inteface';
import { IAteCustomContentBoxWidget } from './interface/customContentBox.interface';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { getDcbLmsDefaultLanguage } from 'store/bootstrap/boostrap.selector';

export default function AteCustomContentBox(props: IAteCustomContentBoxWidget) {
    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);

    return (
        <Flex
            style={
                {
                    minHeight: props.min_height,
                    color: props.text_color,
                    backgroundColor: props.background.color,
                    backgroundImage: `url("${props.background.image_url}")`,
                    alignItems: HorizontalAlignValueToFlexEnum[props.horizontal_align],
                    justifyContent: VerticalAlignValueToFlexEnum[props.vertical_align]
                }
            }
            data-testid="custom-content-box"
            className="custom-content-box"
            column>
            {
                props.title ?
                    <Flex
                        className="title-container">
                        <StyledText
                            size={'large'}
                            weight={'bold'}
                        >
                            {getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex> : ''
            }
            {
                props.description ?
                    <Flex
                        className="description-container">
                        <StyledText
                            size={'medium'}
                        >
                            {getWidgetTranslatedLabel(props.description, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex> : ''
            }
        </Flex>
    )
}
