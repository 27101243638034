import { IAteMyCoursesAndLpWidget } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface';
import { IAteSingleCoursePlayerPreviewWidget } from 'components/widgets/coursePlayer/interface/coursePlayer.interface';
import { IAteCustomContentBoxWidget } from 'components/widgets/customContentBox/interface/customContentBox.interface';
import { IAteStaticCourseListWidget } from 'components/widgets/staticCourseList/interfaces/staticCourseList.interface';

export interface IConfigResponse {
    general_settings: IGeneralSettings,
    rows: IDashboardLayoutRow[]
}

export interface IGeneralSettings {
    domain: string;
}

export interface IDashboardLayoutRow {
    columns: IColumn[],
    size: IWidgetLayoutSize,
}

export interface IColumn {
    widgets: IWidget[],
    size: IWidgetLayoutSize,
}

export enum WidgetTypeEnum {
    CUSTOM_CONTENT_BOX = 'custom-content-box',
    SINGLE_COURSE_PLAYER = 'single-course-player',
    MY_COURSES_AND_LEARNING_PLANS = 'my-courses-learning-plans',
    STATIC_COURSE_LIST = 'static-course-list',
    PLACEHOLDER = 'placeholder-widget'
}

export interface IWidget {
    type: WidgetTypeEnum,
    size: IWidgetLayoutSize,
    settings: IWidgetSettings
}

interface IWidgetLayoutSize {
    mobile: number,
    desktop: number
}

type IWidgetSettings = IAteCustomContentBoxWidget | IAteMyCoursesAndLpWidget | IAteSingleCoursePlayerPreviewWidget | IAteStaticCourseListWidget;
