export interface AuthStore {
    dcbAccessToken: IDCBToken;
    isLogged: boolean;
    baseUrl: string;
    userNeedToAcceptPolicy: boolean;
    userNeedToAcceptTermsAndConditions: boolean;
    authError?: AuthErrorEnum
}

export interface IDCBToken {
    token: string;
    createdAt?: number;
}

export interface IAuthTokenResponse {
    access_token: string,
    token_type: string,
    scope: string,
    expires_in: number,
}

export interface IErrorTokenResponse {
    error: ErrorTokenEnum;
    error_description: string;
}

export enum ErrorTokenEnum {
    INVALID_GRANT = "invalid_grant"
}

export enum AuthErrorEnum {
    UNREGISTERED_TENANT = "unregistered_tenant",
    USER_NOT_FOUND = "user_not_found",
    UNAUTHORIZED_APP = "unauthorized_app"
}
