import DashboardPage from 'components/pages/dashboard/DashboardPage';
import LoginPage from 'components/pages/login/LoginPage';
import MyCoursesAndLpPage from 'components/pages/myCoursesAndLp/MyCoursesAndLpPage';
import CoursePlayerPage from 'components/pages/coursePlayer/CoursePlayerPage';
import { PAGES_INFO, PageInfoEnum } from 'routes/pageSettings';
import LearningPlanPlage from 'components/pages/learningPlan/LearningPlanPage';
import PoliciesPage from 'components/pages/policies/PoliciesPage';
import ConfigurableTabSettingsPage from 'components/pages/configurableTabSettingsPage/ConfigurableTabSettingsPage';
import StaticCourseListPage from 'components/pages/staticCourseList/StaticCourseListPage';

export interface IRoute {
    path: string,
    component: any,
    authRequired: boolean,
    restricted?: boolean,
}

const APP_ROUTES: IRoute[] = [
    {
        path: PAGES_INFO[PageInfoEnum.DASHBOARD].path,
        component: DashboardPage,
        authRequired: true,
    },
    {
        path: PAGES_INFO[PageInfoEnum.MY_COURSES_AND_LP].path,
        component: MyCoursesAndLpPage,
        authRequired: true,
    },
    {
        path: PAGES_INFO[PageInfoEnum.COURSE_PLAYER].path,
        component: CoursePlayerPage,
        authRequired: true,
    },
    {
        path: PAGES_INFO[PageInfoEnum.LEARNING_PLAN].path,
        component: LearningPlanPlage,
        authRequired: true,
    },
    {
        path: PAGES_INFO[PageInfoEnum.LOGIN].path,
        component: LoginPage,
        authRequired: false,
        restricted: true,
    },
    {
        path: PAGES_INFO[PageInfoEnum.POLICIES].path,
        component: PoliciesPage,
        authRequired: true,
    },
    {
        path: PAGES_INFO[PageInfoEnum.CONFIGURABLE_TABS].path,
        component: ConfigurableTabSettingsPage,
        authRequired: false
    },
    {
        path: PAGES_INFO[PageInfoEnum.STATIC_COURSE_LIST].path,
        component: StaticCourseListPage,
        authRequired: true
    }
];

export default APP_ROUTES;
