import { IPageInfo } from 'routes/pageSettings';

export interface IAteBreadcrumbSettings extends IUpdateAteBreadcrumb {
    showBreadcrumb: boolean;
}

export interface IAteBreadcrumbItem extends IPageInfo {
    isItemSelected?: boolean;
}

export interface IUpdateAteBreadcrumb {
    items: IAteBreadcrumbItem[];
    showFilters?: boolean;
}

export enum TitleCharactersLimit {
    XS = 10,
    SM = 20,
    MD = 40,
    LG = 50,
}
