// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as moment from 'moment';
import { CalendarSpec, LocaleSpecification, Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import { CalendarSpecification, HDateLocale } from './h-date.interface';

export class HDate {
    private moment: Moment;

    /**
     * Sets locale for HDate objects.
     * Note: all HDate objects will be affected by this setting.
     * @param language The language you want to associate to the locale info.
     * @param locale The locale settings.
     */
    static setLocale(language: string, locale: HDateLocale): void {
        moment.updateLocale(language, locale as LocaleSpecification);
    }

    /**
     * Creates a new HDate instance.
     * @param date The date from which you want to create a HDate. If not specified, current time gets used.
     * @returns A new HDate instance.
     */
    constructor(date?: HDate | Date | string | number | moment.Moment) {
        momentTimezone.tz.setDefault('UTC');

        if (!date) {
            date = new Date();
        }

        if (date instanceof HDate) {
            date = date.toDate();
        }

        this.moment = momentTimezone.tz(date, 'UTC');

        return this;
    }

    /**
     * Formats a HDate according to passed format and timezone strings.
     * @param format The preferred format.
     * @param timezone The preferred timezone.
     * @returns The formatted date.
     */
    formatTimezoned(format: string, timezone: string): string {
        return momentTimezone(this.moment)
            .tz(timezone)
            .format(format);
    }

    /**
     * Formats a HDate in UTC timezone according to passed format.
     * @param format The preferred format.
     * @returns The UTC formatted date.
     */
    formatUTC(format: string): string {
        return this.moment.format(format);
    }

    // GET FUNCTIONS

    /**
     * Gets the milliseconds.
     * @returns The milliseconds.
     */
    getMilliseconds(): number {
        return this.moment.milliseconds();
    }

    /**
     * Gets the seconds.
     * @returns The seconds.
     */
    getSeconds(): number {
        return this.moment.seconds();
    }

    /**
     * Gets the minutes.
     * @returns The minutes.
     */
    getMinutes(): number {
        return this.moment.minutes();
    }

    /**
     * Gets the hours.
     * @returns The hours.
     */
    getHours(): number {
        return this.moment.hours();
    }

    /**
     * Gets the day of the month.
     * @returns The day of the month.
     */
    getDateOfMonth(): number {
        return this.moment.date();
    }

    /**
     * Gets the day of the week.
     * @returns The day of the week.
     */
    getDayOfWeek(): number {
        return this.moment.day();
    }

    /**
     * Gets the day of the week according to the locale.
     * @returns The day of the week according to the locale.
     */
    getDayOfWeekLocale(): number {
        return this.moment.weekday();
    }

    /**
     * Gets the ISO day of the week with 1 being Monday and 7 being Sunday.
     * Reference: https://en.wikipedia.org/wiki/ISO_week_date
     * @returns The ISO day of the week.
     */
    getISODayOfWeek(): number {
        return this.moment.isoWeekday();
    }

    /**
     * Gets the day of the year.
     * @returns The day of the year.
     */
    getDayOfYear(): number {
        return this.moment.dayOfYear();
    }

    /**
     * Gets the number of days in the current month.
     * @returns The number of days in the current month.
     */
    getDaysInMonth(): number {
        return this.moment.daysInMonth();
    }

    /**
     * Gets the week of the year.
     * @returns The week of the year.
     */
    getWeekOfYear(): number {
        return this.moment.week();
    }

    /**
     * Gets the ISO week of the year.
     * Reference: https://en.wikipedia.org/wiki/ISO_week_date
     * @returns The ISO week of the year.
     */
    getISOWeekOfYear(): number {
        return this.moment.isoWeek();
    }

    /**
     * Gets the month.
     * @returns The month.
     */
    getMonth(): number {
        return this.moment.month();
    }

    /**
     * Gets the quarter.
     * @returns The quarter.
     */
    getQuarter(): number {
        return this.moment.quarter();
    }

    /**
     * Gets the year.
     * @returns The year.
     */
    getYear(): number {
        return this.moment.year();
    }

    /**
     * Gets the number of weeks according to locale in the current moment's year.
     * @returns The number of weeks according to locale in the current moment's year.
     */
    getWeeksInYear(): number {
        return this.moment.weeksInYear();
    }

    /**
     * Gets the number of weeks in the current moment's year, according to ISO weeks.
     * Reference: https://en.wikipedia.org/wiki/ISO_week_date
     * @returns The number of weeks in the current moment's year, according to ISO weeks.
     */
    getISOWeeksInYear(): number {
        return this.moment.isoWeeksInYear();
    }

    /**
     * Gets the time from now in words.
     * If HDate is earlier than now, a negative time will be returned.
     * @param hideSuffix Whether to hide the 'in' preposition.
     * @returns The time from now in words.
     */
    getTimeFromNowInWords(hideSuffix = false): string {
        return this.moment.fromNow(hideSuffix);
    }

    /**
     * Gets the time to now in words.
     * If HDate is later than now, a negative time will be returned.
     * @param hideSuffix Whether to hide the 'ago' preposition.
     * @returns The time to now in words.
     */
    getTimeToNowInWords(hideSuffix = false): string {
        return this.moment.toNow(hideSuffix);
    }

    /**
     * Gets the time from provided HDate in words.
     * If current HDate is earlier than provided HDate, a negative time will be returned.
     * @param x The compared HDate.
     * @param hideSuffix Whether to hide the 'in' preposition.
     * @returns The time from now in words.
     */
    getTimeFromXInWords(x: HDate, hideSuffix = false): string {
        return this.moment.from(x.toDate(), hideSuffix);
    }

    /**
     * Gets the time to provided HDate in words.
     * If current HDate is later than provided HDate, a negative time will be returned.
     * @param x The compared HDate.
     * @param hideSuffix Whether to hide the 'ago' preposition.
     * @returns The time to now in words.
     */
    getTimeToXInWords(x: HDate, hideSuffix = false): string {
        return this.moment.to(x.toDate(), hideSuffix);
    }

    /**
     * Gets the time in words relative to a given referenceTime (which defaults to now).
     * It changes from getTimeFromXInWords/getTimeToXInWords functions as getCalendarTime will format a date
     * with different strings depending on how close to referenceTime (now by default) the current HDate is.
     * Those strings are localized and can be customized.
     * @returns The time in words relative to the given referenceTime.
     */
    getCalendarTime(referenceTime: HDate = null, formats: CalendarSpecification = null): string {
        return this.moment.calendar(referenceTime.toDate(), formats as CalendarSpec);
    }

    /**
     * Gets the difference from a provided referenceTime in given unitOfTime.
     * @returns The difference in given unitOfTime.
     */
    getDifference(referenceTime: HDate, unitOfTime?): number {
        return this.moment.diff(referenceTime.toDate(), unitOfTime);
    }

    /**
     * Gets the number of milliseconds since the Unix Epoch.
     * @returns The number of milliseconds since the Unix Epoch.
     */
    getTimestampMilliseconds(): number {
        return this.moment.valueOf();
    }

    /**
     * Gets the number of seconds since the Unix Epoch.
     * @returns The number of seconds since the Unix Epoch.
     */
    getTimestampSeconds(): number {
        return this.moment.unix();
    }

    /**
     * Sets the milliseconds.
     * @returns The HDate object.
     */
    setMilliseconds(milliseconds: number): HDate {
        this.moment.milliseconds(milliseconds);

        return this;
    }

    /**
     * Sets the seconds.
     * @returns The HDate object.
     */
    setSeconds(seconds: number): HDate {
        this.moment.seconds(seconds);

        return this;
    }

    /**
     * Sets the minutes.
     * @returns The HDate object.
     */
    setMinutes(minutes: number): HDate {
        this.moment.minutes(minutes);

        return this;
    }

    /**
     * Sets the hours.
     * @returns The HDate object.
     */
    setHours(hours: number): HDate {
        this.moment.hours(hours);

        return this;
    }

    /**
     * Sets the day of the month.
     * @returns The HDate object.
     */
    setDateOfMonth(date: number): HDate {
        this.moment.date(date);

        return this;
    }

    /**
     * Sets the day of the week.
     * @returns The HDate object.
     */
    setDayOfWeek(day: number): HDate {
        this.moment.day(day);

        return this;
    }

    /**
     * Sets the day of the week according to the locale.
     * @returns The HDate object.
     */
    setDayOfWeekLocale(day: number): HDate {
        this.moment.weekday(day);

        return this;
    }

    /**
     * Sets the ISO day of the week with 1 being Monday and 7 being Sunday.
     * Reference: https://en.wikipedia.org/wiki/ISO_week_date
     * @returns The HDate object.
     */
    setISODayOfWeek(day: number): HDate {
        this.moment.isoWeekday(day);

        return this;
    }

    /**
     * Sets the day of the year.
     * @returns The HDate object.
     */
    setDayOfYear(day: number): HDate {
        this.moment.dayOfYear(day);

        return this;
    }

    /**
     * Sets the week of the year.
     * @returns The HDate object.
     */
    setWeekOfYear(week: number): HDate {
        this.moment.week(week);

        return this;
    }

    /**
     * Sets the ISO week of the year.
     * Reference: https://en.wikipedia.org/wiki/ISO_week_date
     * @returns The HDate object.
     */
    setISOWeekOfYear(week: number): HDate {
        this.moment.isoWeek(week);

        return this;
    }

    /**
     * Sets the month.
     * @returns The HDate object.
     */
    setMonth(month: number): HDate {
        this.moment.month(month);

        return this;
    }

    /**
     * Sets the quarter.
     * @returns The HDate object.
     */
    setQuarter(quarter: number): HDate {
        this.moment.quarter(quarter);

        return this;
    }

    /**
     * Sets the year.
     * @returns The HDate object.
     */
    setYear(year: number): HDate {
        this.moment.year(year);

        return this;
    }

    /**
     * Converts the current HDate to a JavaScript Date object.
     * @returns The JS Date object.
     */
    toDate(): Date {
        return this.moment.toDate();
    }

    /**
     * Formats the current HDate to the ISO8601 standard.
     * @returns The ISO8601 formatted date.
     */
    toISOString(): string {
        return this.moment.toISOString();
    }

    /**
     * Returns an English string in a similar format to JS Date's .toString().
     * @returns The formatted date.
     */
    toString(): string {
        return this.moment.toString();
    }

    /**
     * Adds the provided amount of milliseconds to current HDate.
     * @param ms The amount of milliseconds to add.
     * @returns The HDate object.
     */
    addMilliseconds(ms: number): HDate {
        this.moment.add(ms, 'milliseconds');

        return this;
    }

    /**
     * Adds the provided amount of seconds to current HDate.
     * @param s The amount of seconds to add.
     * @returns The HDate object.
     */
    addSeconds(s: number): HDate {
        this.moment.add(s, 'seconds');

        return this;
    }

    /**
     * Adds the provided amount of minutes to current HDate.
     * @param m The amount of minutes to add.
     * @returns The HDate object.
     */
    addMinutes(m: number): HDate {
        this.moment.add(m, 'minutes');

        return this;
    }

    /**
     * Adds the provided amount of hours to current HDate.
     * @param h The amount of hours to add.
     * @returns The HDate object.
     */
    addHours(h: number): HDate {
        this.moment.add(h, 'hours');

        return this;
    }

    /**
     * Adds the provided amount of days to current HDate.
     * @param d The amount of days to add.
     * @returns The HDate object.
     */
    addDays(d: number): HDate {
        this.moment.add(d, 'days');

        return this;
    }

    /**
     * Adds the provided amount of weeks to current HDate.
     * @param w The amount of weeks to add.
     * @returns The HDate object.
     */
    addWeeks(w: number): HDate {
        this.moment.add(w, 'weeks');

        return this;
    }

    /**
     * Adds the provided amount of months to current HDate.
     * @param m The amount of months to add.
     * @returns The HDate object.
     */
    addMonths(m: number): HDate {
        this.moment.add(m, 'months');

        return this;
    }

    /**
     * Adds the provided amount of quarters to current HDate.
     * @param q The amount of quarters to add.
     * @returns The HDate object.
     */
    addQuarters(q: number): HDate {
        this.moment.add(q, 'quarters');

        return this;
    }

    /**
     * Adds the provided amount of years to current HDate.
     * @param y The amount of years to add.
     * @returns The HDate object.
     */
    addYears(y: number): HDate {
        this.moment.add(y, 'years');

        return this;
    }

    /**
     * Subtracts the provided amount of milliseconds from current HDate.
     * @param ms The amount of milliseconds to subtract.
     * @returns The HDate object.
     */
    subtractMilliseconds(ms: number): HDate {
        this.moment.subtract(ms, 'milliseconds');

        return this;
    }

    /**
     * Subtracts the provided amount of seconds from current HDate.
     * @param s The amount of seconds to subtract.
     * @returns The HDate object.
     */
    subtractSeconds(s: number): HDate {
        this.moment.subtract(s, 'seconds');

        return this;
    }

    /**
     * Subtracts the provided amount of minutes from current HDate.
     * @param m The amount of minutes to subtract.
     * @returns The HDate object.
     */
    subtractMinutes(m: number): HDate {
        this.moment.subtract(m, 'minutes');

        return this;
    }

    /**
     * Subtracts the provided amount of hours from current HDate.
     * @param h The amount of hours to subtract.
     * @returns The HDate object.
     */
    subtractHours(h: number): HDate {
        this.moment.subtract(h, 'hours');

        return this;
    }

    /**
     * Subtracts the provided amount of days from current HDate.
     * @param d The amount of days to subtract.
     * @returns The HDate object.
     */
    subtractDays(d: number): HDate {
        this.moment.subtract(d, 'days');

        return this;
    }

    /**
     * Subtracts the provided amount of weeks from current HDate.
     * @param w The amount of weeks to subtract.
     * @returns The HDate object.
     */
    subtractWeeks(w: number): HDate {
        this.moment.subtract(w, 'weeks');

        return this;
    }

    /**
     * Subtracts the provided amount of months from current HDate.
     * Pay attention to the DST. If you subtract 1 month to a date that
     * is in the first 31 days after DST you'll have a new date with +1/-1 hour.
     * @param m The amount of months to subtract.
     * @returns The HDate object.
     */
    subtractMonths(m: number): HDate {
        this.moment.subtract(m, 'months');

        return this;
    }

    /**
     * Subtracts the provided amount of quarters from current HDate.
     * @param q The amount of quarters to subtract.
     * @returns The HDate object.
     */
    subtractQuarters(q: number): HDate {
        this.moment.subtract(q, 'quarters');

        return this;
    }

    /**
     * Subtracts the provided amount of years from current HDate.
     * @param y The amount of years to subtract.
     * @returns The HDate object.
     */
    subtractYears(y: number): HDate {
        this.moment.subtract(y, 'years');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the second.
     * @returns The HDate object.
     */
    startOfSecond(): HDate {
        this.moment.startOf('second');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the minute.
     * @returns The HDate object.
     */
    startOfMinute(): HDate {
        this.moment.startOf('minute');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the hour.
     * @returns The HDate object.
     */
    startOfHour(): HDate {
        this.moment.startOf('hour');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the day.
     * @returns The HDate object.
     */
    startOfDay(): HDate {
        this.moment.startOf('day');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the ISO week.
     * @returns The HDate object.
     */
    startOfIsoWeek(): HDate {
        this.moment.startOf('isoWeek');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the week.
     * @returns The HDate object.
     */
    startOfWeek(): HDate {
        this.moment.startOf('week');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the quarter.
     * @returns The HDate object.
     */
    startOfQuarter(): HDate {
        this.moment.startOf('quarter');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the month.
     * @returns The HDate object.
     */
    startOfMonth(): HDate {
        this.moment.startOf('month');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the start of the year.
     * @returns The HDate object.
     */
    startOfYear(): HDate {
        this.moment.startOf('year');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the second.
     * @returns The HDate object.
     */
    endOfSecond(): HDate {
        this.moment.endOf('second');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the minute.
     * @returns The HDate object.
     */
    endOfMinute(): HDate {
        this.moment.endOf('minute');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the hour.
     * @returns The HDate object.
     */
    endOfHour(): HDate {
        this.moment.endOf('hour');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the day.
     * @returns The HDate object.
     */
    endOfDay(): HDate {
        this.moment.endOf('day');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the ISO week.
     * @returns The HDate object.
     */
    endOfIsoWeek(): HDate {
        this.moment.endOf('isoWeek');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the week.
     * @returns The HDate object.
     */
    endOfWeek(): HDate {
        this.moment.endOf('week');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the quarter.
     * @returns The HDate object.
     */
    endOfQuarter(): HDate {
        this.moment.endOf('quarter');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the month.
     * @returns The HDate object.
     */
    endOfMonth(): HDate {
        this.moment.endOf('month');

        return this;
    }

    /**
     * Mutates the current HDate by setting it to the end of the year.
     * @returns The HDate object.
     */
    endOfYear(): HDate {
        this.moment.endOf('year');

        return this;
    }

    /**
     * Checks if the current HDate is before the compared HDate.
     * @param dateToCompare The HDate to compare.
     * @returns Whether the current HDate is before the compared HDate.
     */
    isBefore(dateToCompare: HDate): boolean {
        return this.moment.isBefore(dateToCompare.toDate());
    }

    /**
     * Checks if the current HDate is after the compared HDate.
     * @param dateToCompare The HDate to compare.
     * @returns Whether the current HDate is after the compared HDate.
     */
    isAfter(dateToCompare: HDate): boolean {
        return this.moment.isAfter(dateToCompare.toDate());
    }

    /**
     * Checks if the current HDate is the same as the compared HDate.
     * @param dateToCompare The HDate to compare.
     * @returns Whether the current HDate is the same as the compared HDate.
     */
    isSame(dateToCompare: HDate): boolean {
        return this.moment.isSame(dateToCompare.toDate());
    }

    /**
     * Checks if the current HDate is the same as or before the compared HDate.
     * @param dateToCompare The HDate to compare.
     * @returns Whether the current HDate is the same as or before the compared HDate.
     */
    isSameOrBefore(dateToCompare: HDate): boolean {
        return this.moment.isSameOrBefore(dateToCompare.toDate());
    }

    /**
     * Checks if the current HDate is the same as or after the compared HDate.
     * @param dateToCompare The HDate to compare.
     * @returns Whether the current HDate is the same as or after the compared HDate.
     */
    isSameOrAfter(dateToCompare: HDate): boolean {
        return this.moment.isSameOrAfter(dateToCompare.toDate());
    }

    /**
     * Checks if the current HDate is between two other HDates.
     * @param initialRangeDate The initial range HDate.
     * @param endRangeDate The end range HDate.
     * @returns Whether the current HDate is between the provided HDates.
     */
    isBetween(initialRangeDate: HDate, endRangeDate: HDate): boolean {
        return this.moment.isBetween(initialRangeDate.toDate(), endRangeDate.toDate());
    }

    /**
     * Checks if the current HDate's year is a leap year.
     * @returns Whether the current HDate's year is a leap year.
     */
    isLeapYear(): boolean {
        return this.moment.isLeapYear();
    }
}
