import { DICTIONARY } from "dictionary";

export const DASHBOARD_TITLE = DICTIONARY.dashboard;
export const LOGIN_TITLE = DICTIONARY.login;
export const AUTH_TITLE = DICTIONARY.auth;
export const MY_COURSES_AND_LP_TITLE = DICTIONARY.myCoursesLearningPlans;
export const COURSE_PLAYER = DICTIONARY.coursePlayer;
export const LEARNING_PLAN = DICTIONARY.learningPlan;
export const PRIVACY_POLICY = DICTIONARY.privacyPolicy;
export const CONFIGURABLE_TABS_TITLE = DICTIONARY.configurableTabsSettings;
export const STATIC_COURSE_LIST = DICTIONARY.staticCourseList;
