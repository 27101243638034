import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getIsLogged } from 'store/auth/auth.selectors';
import { PageInfoEnum, PAGES_INFO } from './pageSettings';
import { IRoute } from './routes.config';

export default function PrivateRoute({ component: Component, ...rest }: Omit<IRoute, 'authRequired'>) {
    const isLogged = useSelector(getIsLogged);

    return (
        <Route
            {...rest}
            render={props => (
                isLogged ?
                    <Component
                        {...props}
                    /> :
                    <Redirect
                        to={{
                            pathname: PAGES_INFO[PageInfoEnum.LOGIN].path,
                            state: { from: props.location },
                        }}
                    />
            )} />
    );
}
