import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAteBreadcrumbSettings, IUpdateAteBreadcrumb } from 'components/shared/breadcrumb/interfaces/breadcrumb.interface';

const breadcrumbInitialState: IAteBreadcrumbSettings = {
    showBreadcrumb: false,
    showFilters: false,
    items: []
};

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState: breadcrumbInitialState,
    reducers: {
        setBreadcrumbSettings: (state, action: PayloadAction<IAteBreadcrumbSettings>) => {
            return {
                ...state,
                ...action.payload
            };
        },
        updateBreadcrumbItems: (state, action: PayloadAction<IUpdateAteBreadcrumb>) => {
            return {
                ...state,
                ...action.payload
            };
        },
        resetBreadcrumb: () => {
            return {
                showBreadcrumb: false,
                items: [],
                showFilters: false
            };
        }
    }
})

export const {
    updateBreadcrumbItems,
    resetBreadcrumb,
    setBreadcrumbSettings
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
