import { Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, MenuButton } from '@fluentui/react-components';
import React from 'react';
import { MoreHorizontalRegular } from '@fluentui/react-icons';
import { IMenuItem } from 'components/shared/cards/interfaces/card.interface';

export const getCardMenu = (items: IMenuItem[]) => {
    if (!items.length) {
        return
    }

    return (
        <Menu>
            <MenuTrigger>
                <MenuButton icon={<MoreHorizontalRegular/>}
                            appearance="subtle"
                            onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                                e.stopPropagation();
                            }}>
                </MenuButton>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {items.map(item =>
                        <MenuItem
                            key={item.key}
                            onClick={item.onClick}>
                            {item.content}
                        </MenuItem>
                    )}
                </MenuList>
            </MenuPopover>
        </Menu>
    )
};
