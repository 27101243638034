import { IMyCoursesAndLpItem } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLpResponse.interface';
import { MyCoursesAndLpViewEnum } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface';
import { BadgeColor, IAteCard } from 'components/shared/cards/interfaces/card.interface';
import { CourseStatusEnum, CourseTypeEnum, ICourseInfo } from 'intefaces/course.interface';
import {
    getContentLanguageCodeFromEndpointLanguage,
    getCourseBadgeStatusInfo,
    getCourseTypeLabel,
    getDefaultCourseThumbnail,
    getFormattedDuration,
    getCourseIcon
} from 'helpers/course.helpers';
import { IBootstrap } from 'intefaces/Bootstrap';
import { DEFAULT_DATE_LENGTH, getContextLocale, getContextTimezone, getDateFormat } from 'helpers/h-date/date-format';
import { HDate } from 'helpers/h-date/h-date/h-date';
import { HDuration } from 'helpers/h-date/h-date/h-duration';
import { ISession } from 'intefaces/Session';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';

interface IValidityStatus {
    valid: boolean,
    message: string
}

export const myCourseAndLpItemtoCard = (
    myCourseAndLpItem: IMyCoursesAndLpItem,
    view: MyCoursesAndLpViewEnum,
    boostrap: IBootstrap,
    session: ISession,
    baseUrl: string
): IAteCard => {
    return {
        id: myCourseAndLpItem.id,
        title: myCourseAndLpItem.name,
        imageUrl: myCourseAndLpItem.image_url || getDefaultCourseThumbnail(myCourseAndLpItem.type, baseUrl),
        subtitle: getCardSubtitle(myCourseAndLpItem, boostrap, session),
        leftLabelColor: getCardLeftLabelColor(myCourseAndLpItem),
        leftLabel: getCardLeftLabel(myCourseAndLpItem),
        rightLabel: myCourseAndLpItem.status ? getCourseBadgeStatusInfo(myCourseAndLpItem.status)?.label : '',
        rightLabelColor: myCourseAndLpItem.status ? getCourseBadgeStatusInfo(myCourseAndLpItem.status)?.color : undefined,
        detailsFirstItem: getCardDetailsFirstItem(myCourseAndLpItem, boostrap),
        detailsSecondItem: getCardDetailsSecondItem(myCourseAndLpItem, view === MyCoursesAndLpViewEnum.GRID),
        detailsThirdItem: getCardDetailsThirdItems(myCourseAndLpItem),
        footerIcon: getCourseIcon(myCourseAndLpItem.type),
        footerLabel: getCardFooterLabel(myCourseAndLpItem, view === MyCoursesAndLpViewEnum.GRID),
        rating: myCourseAndLpItem.rating,
        locked: !myCourseAndLpItem.can_enter,
        stacked: myCourseAndLpItem.type === CourseTypeEnum.LEARNING_PLAN
    };
}

export const isContentOutdated = (myCourseAndLpItem: IMyCoursesAndLpItem): boolean => {
    const expirationDate = myCourseAndLpItem.enrollment_date_end || myCourseAndLpItem.enrollment_date_end;
    return (
        typeof myCourseAndLpItem.outdated !== 'undefined' &&
        myCourseAndLpItem.outdated.totalUsers !== null &&
        myCourseAndLpItem.outdated.totalUsers > 0 &&
        !!expirationDate &&
        myCourseAndLpItem.can_enter
    );
}

export const getCardLeftLabelColor = (myCourseAndLpItem: IMyCoursesAndLpItem): BadgeColor => {
    return myCourseAndLpItem.hidden || isContentOutdated(myCourseAndLpItem) ? 'danger' : 'important';
}

export const getCardLeftLabel = (myCourseAndLpItem: IMyCoursesAndLpItem): string | undefined => {
    let label;

    if (myCourseAndLpItem.level === 'instructor') {
        label = AteLocalize.getLocalizedLabel(DICTIONARY.instructor);
    }

    if (myCourseAndLpItem.hidden) {
        label = AteLocalize.getLocalizedLabel(DICTIONARY.hidden);
    }

    if (isContentOutdated(myCourseAndLpItem)) {
        label = AteLocalize.getLocalizedLabel(DICTIONARY.outdated);
    }

    return label;
};

export const getCardSubtitle = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo, bootstrap: IBootstrap, session: ISession): string | undefined => {
    let statusDetails;

    if (!myCourseAndLpItem.can_enter) {
        statusDetails = myCourseAndLpItem.can_enter_reason;

        switch (statusDetails) {
            case 'lp_subscription_expired':
            case 'days_validity_expired':
                statusDetails = AteLocalize.getLocalizedLabel(DICTIONARY.expired);
                break;
            case 'prerequisites':
                statusDetails = AteLocalize.getLocalizedLabel(DICTIONARY.prerequisitesNotSatisfied);
                break;
        }

        if (myCourseAndLpItem.status === CourseStatusEnum.SUSPENDED) {
            statusDetails = AteLocalize.getLocalizedLabel(DICTIONARY.enrollmentStatusSuspended);
        }

        if (myCourseAndLpItem.status === CourseStatusEnum.OVERBOOKING) {
            statusDetails = AteLocalize.getLocalizedLabel(DICTIONARY.enrollmentStatusOverbooking);
        }

        const courseValidityStatus = getValidityStatus(myCourseAndLpItem, bootstrap, session);

        if (!courseValidityStatus.valid) {
            statusDetails = courseValidityStatus.message;
        }
    } else if (
        (myCourseAndLpItem.enrollment_date_end || myCourseAndLpItem.enrollment_date_end) &&
        (myCourseAndLpItem.status !== CourseStatusEnum.COMPLETED && myCourseAndLpItem.status !== CourseStatusEnum.DONE)
    ) {
        statusDetails = getExpirationLabel(myCourseAndLpItem, bootstrap, session);
    }

    return statusDetails;
};

export const getValidityStatus = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo, bootstrap: IBootstrap, session: ISession): IValidityStatus => {
    const result: IValidityStatus = {
        valid: true,
        message: '',
    };

    const now = new HDate();
    const startDate: string =
        myCourseAndLpItem.enrollment_date_start || myCourseAndLpItem.course_date_start || '';
    const endDate: string =
        myCourseAndLpItem.enrollment_date_end || myCourseAndLpItem.course_date_end || '';

    const courseNotStarted = !!startDate && now.isBefore(new HDate(startDate));
    const courseEnded = !!endDate && now.isAfter(new HDate(endDate));

    if (courseNotStarted) {
        result.valid = false;
        const date = (new HDate(startDate)).formatTimezoned(
            getDateFormat(getContextLocale(bootstrap, session), DEFAULT_DATE_LENGTH),
            getContextTimezone(bootstrap, session),
        );

        result.message = AteLocalize.getLocalizedLabel(DICTIONARY.activeFrom(date));
    } else if (!myCourseAndLpItem.soft_deadline && courseEnded) {
        result.valid = false;
        result.message = AteLocalize.getLocalizedLabel(DICTIONARY.expired);
    }

    return result;
};

export const getExpirationLabel = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo, bootstrap: IBootstrap, session: ISession): string => {
    let expirationLabel: string;
    const today = new HDate();
    const endDate = myCourseAndLpItem.enrollment_date_end || myCourseAndLpItem.enrollment_date_end;

    if (isFormalContentExpired(myCourseAndLpItem)) {
        const howMany =
            today.getTimestampMilliseconds() -
            new HDate(endDate).getTimestampMilliseconds();
        const overdue = new HDuration(howMany);
        expirationLabel = `${overdue.toWords()} ${AteLocalize.getLocalizedLabel(DICTIONARY.overdue)}`;
    } else {
        const dateFormat = getDateFormat(getContextLocale(bootstrap, session), DEFAULT_DATE_LENGTH);
        expirationLabel = AteLocalize.getLocalizedLabel(DICTIONARY.expiringOn(dateFormat));
    }

    return expirationLabel;
};

export const isFormalContentExpired = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo): boolean => {
    const today = new HDate();
    const endDate = myCourseAndLpItem.enrollment_date_end || myCourseAndLpItem.enrollment_date_end;
    return endDate ? today.isAfter(new HDate(endDate)) : false;
};

export const getCardDetailsFirstItem = (myCourseAndLpItem: IMyCoursesAndLpItem, bootstrap: IBootstrap): string | undefined => {
    return myCourseAndLpItem.type === CourseTypeEnum.LEARNING_PLAN ?
        `${myCourseAndLpItem.courses_count} ${myCourseAndLpItem.courses_count === 1 ?
            AteLocalize.getLocalizedLabel(DICTIONARY.course) :
            AteLocalize.getLocalizedLabel(DICTIONARY.courses)}` :
        getContentLanguageCodeFromEndpointLanguage(myCourseAndLpItem?.lang_code, bootstrap.active_languages);
};

export const getCardDetailsSecondItem = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo, isGridView: boolean): string | undefined => {
    return isGridView ?
        myCourseAndLpItem.duration > 0 ? getFormattedDuration(myCourseAndLpItem.duration) : undefined
        : getCourseTypeLabel(myCourseAndLpItem.type);
};

export const getCardDetailsThirdItems = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo): string | undefined => {
    return myCourseAndLpItem.duration > 0 ? getFormattedDuration(myCourseAndLpItem.duration) : undefined;
};

export const getCardFooterLabel = (myCourseAndLpItem: IMyCoursesAndLpItem | ICourseInfo, isGridView: boolean): string | undefined => {
    return isGridView ? getCourseTypeLabel(myCourseAndLpItem.type) : undefined;
};
