import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DcbCustomTranslationsType, DcbTranslations, ITranslationsReducer } from "intefaces/Translations";
import { DEFAULT_LANGUAGE } from "services/translations/AteLocalize";

export const translationsInitialState: ITranslationsReducer = {
    isTranslationsFlowCompleted: false,
    languageInUse: DEFAULT_LANGUAGE,
};

const REDUCER_NAME = 'translations';

const translations = createSlice({
    name: REDUCER_NAME,
    initialState: translationsInitialState,
    reducers: {
        updateReadyStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isTranslationsFlowCompleted: action.payload
            };
        },
        initTranslations: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                languageInUse: action.payload,
                userLanguageInMSTeamsApp: action.payload
            }
        },
        updateTranslations: (state, action: PayloadAction<DcbTranslations>) => {
            //The object key corresponds to the language of the translation.
            //If it is an empty object, the language in use will be in English
            const languageInUse = Object.getOwnPropertyNames(action.payload)[0] || DEFAULT_LANGUAGE;

            return {
                ...state,
                languageInUse,
                translations: action.payload
            }
        },
        updateCustomTranslations: (state, action: PayloadAction<DcbCustomTranslationsType>) => {
            return {
                ...state,
                customTranslations: action.payload
            }
        },
        updateLanguageInMSTeamsApp: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                userLanguageInMSTeamsApp: action.payload
            }
        },
        updateUserLanguageInLMS: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                userLanguageInLMS: action.payload
            }
        }
    },
});

export const {
    updateReadyStatus,
    updateTranslations,
    initTranslations,
    updateCustomTranslations,
    updateLanguageInMSTeamsApp,
    updateUserLanguageInLMS
} = translations.actions;
export default translations.reducer;
