import { AxiosResponse } from "axios";
import { SubPolicieAnswer } from "intefaces/policies.interface";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { getHttpClient, HttpConfig } from "./HttpClient"

export const getPrivacyPolicy = (authConfig: HttpConfig, id: number) => {
    return from(getHttpClient(authConfig).get<AxiosResponse<any>>(`/manage/v1/policy/${id}/versions/latest`))
        .pipe(
            map(({ data }) => data.data)
        );
}

export const acceptPrivacyPolicy = (authConfig: HttpConfig, id: number, answer: boolean, subPolicies: SubPolicieAnswer[]): Observable<void> => {
    const body = {
        policy: {
            id,
            answer,
            sub_policies: subPolicies,
        }
    };

    return from(getHttpClient(authConfig).post<AxiosResponse<void>>(`/manage/v1/policy/track`, body))
        .pipe(
            map(({ data }) => data.data)
        );
}

export const getTermsAndConditions = (authConfig: HttpConfig, id: number) => {
    return from(getHttpClient(authConfig).get<AxiosResponse<any>>(`/manage/v1/termsandconditions/${id}/versions/latest`))
        .pipe(
            map(({ data }) => data.data)
        );
}

export const acceptTermsAndConditions = (authConfig: HttpConfig, id: number, answer: boolean): Observable<void> => {
    const body = {
        tc: {
            id,
            answer,
        }
    };

    return from(getHttpClient(authConfig).post<AxiosResponse<void>>(`/manage/v1/termsandconditions/track`, body))
        .pipe(
            map(({ data }) => data.data)
        );
}


