export enum IconColorEnum {
    ACCENT = "accent",
    ACCENT_PRIMARY = "accent-primary",
    ACCENT_SECONDARY = "accent-secondary",
    DARK = "dark",
    NEUTRAL = "neutral",
    NEGATIVE = "negative",
    SUCCESS = "success",
    ALERT = "alert",
    ERROR = "error",
    RATING = "rating",
    GOOGLE = "google",
    FACEBOOK = "facebook",
    TWITTER = "twitter",
    ZENDESK = "zendesk",
    OKTA = "okta",
    AUTH0 = "auth0",
    PAYPAL = "paypal",
    DRIVE = "drive",
    LINKEDIN = "linkedin",
    OPENID = "openid"
}
