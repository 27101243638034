import { StyledText } from '@fluentui/react-migration-v0-v9';
import {
    Spinner,
    Button,
    Checkbox,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent, DialogActions, DialogTrigger
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { acceptTermsAndConditions, getTermsAndConditions } from 'api/LMSPolicies';
import { addTargetBlankToLinks, useWindowSize } from 'helpers/commons.helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl, getDcbAccessToken } from 'store/auth/auth.selectors';
import { updateUserNeedToAcceptTermsAndConditions } from 'store/auth/auth.slice';
import { getDcbLmsDefaultLanguage, getTermsAndConditionsId } from 'store/bootstrap/boostrap.selector';
import './TermsAndConditionsDialog.scss';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { MDZone, LGZone, XLZone } from 'helpers/viewportBreakpoint.helpers';

interface TermsAndConditionsDialogProps {
    isDialogVisible: boolean;
    onDialogVisibilityStatusChanges(isVisible: boolean): void;
}

export default function TermsAndConditionsDialog(props: TermsAndConditionsDialogProps) {
    const dispatch = useDispatch();

    const baseUrl = useSelector(getBaseUrl);
    const accessToken = useSelector(getDcbAccessToken);
    const defaultLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);
    const termsAndConditionsId = useSelector(getTermsAndConditionsId);

    const isMDZone = MDZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    const [isMobile, setIsMobile] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);

    const [termsAndConditionsTitle, setTermsAndConditionsTitle] = useState<string>();
    const [termsAndConditionsBody, setTermsAndConditionsBody] = useState<string>();
    const [termsAndConditionsLabel, setTermsAndConditionsLabel] = useState<string>();

    const [areTermsAndConditionsAccepted, setAreTermsAndConditionsAccepted] = useState<boolean>(false);

    useEffect(() => {
        setIsMobile(!isMDZone && !isLGZone && !isXLZone)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()])

    useEffect(() => {
        setIsLoading(true);

        if (termsAndConditionsId && props.isDialogVisible) {
            getTermsAndConditions({ baseUrl, accessToken }, termsAndConditionsId)
                .subscribe(processTermsAndConditionsResponse)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [termsAndConditionsId, props.isDialogVisible])

    const processTermsAndConditionsResponse = (response: any): void => {
        setTermsAndConditionsTitle(
            response.tc.tc_name[userLanguage] ||
            response.tc.tc_name[defaultLanguage]
        )

        setTermsAndConditionsBody(
            response.tc.tc_body[userLanguage] ||
            response.tc.tc_body[defaultLanguage]
        )

        setTermsAndConditionsLabel(
            response.tc.tc_acceptance_message[userLanguage] ||
            response.tc.tc_acceptance_message[defaultLanguage]
        )

        setAreTermsAndConditionsAccepted(false);
        setIsLoading(false);
    }

    const sendPrivacyPolicyAcceptance = () => {
        if (!areTermsAndConditionsAccepted) {
            return;
        }

        setIsConfirmButtonLoading(true);

        acceptTermsAndConditions({ accessToken, baseUrl }, termsAndConditionsId, areTermsAndConditionsAccepted)
            .subscribe(() => {
                dispatch(updateUserNeedToAcceptTermsAndConditions(false));
                setIsConfirmButtonLoading(false);
            })
    }


    return (
        <Dialog
            modalType="alert"
            open={props.isDialogVisible}>
            {!isLoading ?
                <DialogSurface
                    className={isMobile ? 'dialog-mobile' : 'dialog-desktop'}>
                    <DialogBody
                        className={isMobile ? 'dialog-mobile' : 'dialog-desktop'}>
                        <DialogTitle
                            action={
                                <DismissRegular
                                    className={'dismiss-button'}
                                    onClick={() => props.onDialogVisibilityStatusChanges(false)}
                                />
                            }>
                            <StyledText
                                style={{ display: termsAndConditionsTitle && termsAndConditionsTitle.length ? 'block' : 'none' }}
                                size={'large'}
                                weight="semibold"
                            >
                                {termsAndConditionsTitle}
                            </StyledText>
                            <StyledText
                                weight="light"
                                size="small"
                            >
                                {AteLocalize.getLocalizedLabel(DICTIONARY.youMustReadAndAgreeToTheTermsConditionsInOrderToProceed)}
                            </StyledText>
                        </DialogTitle>
                        <DialogContent>
                            <p
                                dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(termsAndConditionsBody!) }}>
                            </p>
                        </DialogContent>
                        <DialogActions fluid={true}>
                            <Checkbox
                                label={`${termsAndConditionsLabel} ${AteLocalize.getLocalizedLabel(DICTIONARY.required)}`}
                                checked={areTermsAndConditionsAccepted}
                                onClick={() => setAreTermsAndConditionsAccepted(!areTermsAndConditionsAccepted)}
                                labelPosition="after"
                            />
                            <DialogTrigger>
                                <Button
                                    onClick={() => props.onDialogVisibilityStatusChanges(false)}
                                    disabled={isConfirmButtonLoading}
                                >
                                    {AteLocalize.getLocalizedLabel(DICTIONARY.iDonTAgree)}
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button
                                    appearance="primary"
                                    disabled={!areTermsAndConditionsAccepted || isConfirmButtonLoading}
                                    onClick={() => sendPrivacyPolicyAcceptance()}
                                >
                                    {isConfirmButtonLoading ?
                                        <Spinner size="extra-small" /> :
                                        AteLocalize.getLocalizedLabel(DICTIONARY.iAgree)
                                    }
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
                : <Spinner />}
        </Dialog>
    )
}
