import { AteEpics } from "../middleware";
import { updateBreadcrumbItems } from "./breadcrumb.slice";
import { map, filter } from 'rxjs/operators'
import { setBreadcrumbSettings } from './breadcrumb.slice';

const updateBreadcrumbEpics: AteEpics = action$ =>
    action$.pipe(
        filter(updateBreadcrumbItems.match),
        map(action => {
            const items = action.payload.items.map(item => Object.assign({}, item));
            const showBreadcrumb = !!items.length;
            if (showBreadcrumb) {
                items[items.length - 1].isItemSelected = true;
            }

            const showFilters = action.payload?.showFilters;

            return setBreadcrumbSettings({
                showBreadcrumb,
                items,
                showFilters
            })
        })
    )


export const BREADCRUMB_EPICS = [
    updateBreadcrumbEpics
]
