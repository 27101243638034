import { Flex } from '@fluentui/react-migration-v0-v9';
import { Spinner, Button } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { getAccessToken, getBootstrap, getTenantConfig, getSession } from 'api/BackendCalls';
import { getTenantInfo, getLMSTokenViaEris, setCachedAccessToken } from 'api/Eris';
import { HttpConfig } from 'api/HttpClient';
import { useSelector, useDispatch } from 'react-redux';
import { Subscription, forkJoin } from 'rxjs';
import { getIsLogged, getDcbAccessToken, getAuthenticationError } from 'store/auth/auth.selectors';
import { resetAuthenticationError, setBaseUrl, setDcbAccessToken, setIsLogged, updateAuthenticationError, updateUserNeedToAcceptPolicy, updateUserNeedToAcceptTermsAndConditions } from 'store/auth/auth.slice';
import { updateBootstrap } from 'store/bootstrap/bootstrap.slice';
import { getTenantId } from 'store/msContext/msContext.selector';
import { updateSession } from 'store/session/session.slice';
import { AteBlankSlate } from 'components/shared/blankSlate/AteBlankSlate';
import { WidgetTranslationTypeEnum } from 'intefaces/commons.inteface';
import './LoginPage.scss';
import { AuthErrorEnum } from 'intefaces/Auth';
import AteButtonRaised from 'components/shared/buttons/button-raised/AteButtonRaised';
import { app, authentication } from '@microsoft/teams-js';
import { getCustomTranslation } from 'api/Translations';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { updateCustomTranslations, updateUserLanguageInLMS } from 'store/translations/translations.slice';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';


export default function LoginPage() {
    const dispatch = useDispatch();

    const tenantId = useSelector(getTenantId);
    const isLogged = useSelector(getIsLogged);
    const [azureAccessToken, setAzureAccessToken] = useState<string>();
    const dcbAccessToken = useSelector(getDcbAccessToken);
    const authError = useSelector(getAuthenticationError);
    const appCurrentLanguage = useSelector(getAppCurrentLanguage);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [baseUrlFromEris, setBaseUrlFromEris] = useState<string>();
    const [subscription, setSubscription] = useState<Subscription>();

    /**
     * Retrieve Microsoft Access Token
     * If the silent authentication fails, a consent popup is shown to accecpt the scopes (User.Read, Eris)
     */
    useEffect(() => {
        getMicrosoftAccessToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * After you set up your Microsoft access token, an API call is made to Eris to retrieve your tenant details
     */
    useEffect(() => {
        if (azureAccessToken) {
            getTenantDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [azureAccessToken]);

    useEffect(() => {
        if (dcbAccessToken && baseUrlFromEris) {
            getTenantConfigFromLMS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dcbAccessToken, baseUrlFromEris]);

    useEffect(() => {
        if (!!authError) {
            setIsLoading(false);
        }
    }, [authError]);

    const getMicrosoftAccessToken = (): void => {
        authentication.getAuthToken()
            .then(accessToken => setAzureAccessToken(accessToken))
            .catch(err => {
                console.log('getAuthToken() error:', err);
                dispatch(updateAuthenticationError(AuthErrorEnum.UNAUTHORIZED_APP));
            });
    }

    const getTenantDetails = () => {
        dispatch(resetAuthenticationError());

        if (!azureAccessToken) {
            return;
        }

        getTenantInfo(azureAccessToken)
            .subscribe(
                res => {
                    const platformUrl = res.platform_url;
                    const isCachedTokenAvailable = res.access_token;
                    setBaseUrlFromEris(platformUrl);

                    //The Dashboard sharing has been temporarily disabled due to incompatibility with Link Unfurling
                    //In MOBILE APP, course sharing is always disabled
                    // const isCourseSharingEnabled = res.configuration.content_sharing_enabled && !isMobile;
                    // dispatch(updateCourseSharingStatus(isCourseSharingEnabled));

                    if (!!isCachedTokenAvailable) {
                        dispatch(setDcbAccessToken({
                            token: isCachedTokenAvailable
                        }));
                    } else {
                        getLMSAccessTokenForCurrentUserViaEris(platformUrl);
                    }
                },
                () => {
                    dispatch(updateAuthenticationError(AuthErrorEnum.UNREGISTERED_TENANT));
                }
            );
    }

    const getLMSAccessTokenForCurrentUserViaEris = (platformUrl: string) => {
        if (!azureAccessToken) {
            return;
        }

        getLMSTokenViaEris(azureAccessToken)
            .subscribe(res => {
                const isCachedTokenAvailable = res.access_token;

                if (!!isCachedTokenAvailable) {
                    dispatch(setDcbAccessToken({
                        token: isCachedTokenAvailable
                    }));
                } else {
                    if (res.jwt) {
                        getLMSAccessTokenViaJWT(res.jwt, platformUrl);
                    }
                }
            });
    }

    const getLMSAccessTokenViaJWT = (jwt: string, platformUrl: string) => {
        dispatch(resetAuthenticationError());

        const authConfig: HttpConfig = {
            accessToken: jwt,
            baseUrl: platformUrl
        }

        getAccessToken(authConfig, tenantId)
            .subscribe(
                authInfo => {
                    if (authInfo) {
                        dispatch(setDcbAccessToken({
                            token: authInfo.access_token
                        }));
                    }
                },
                () => {
                    dispatch(updateAuthenticationError(AuthErrorEnum.USER_NOT_FOUND));
                })
    }

    const getTenantConfigFromLMS = () => {
        const authConfig: HttpConfig = {
            accessToken: dcbAccessToken,
            baseUrl: baseUrlFromEris
        }

        getTenantConfig(authConfig, tenantId)
            .subscribe(res => {
                const baseUrl = res.general_settings.domain || baseUrlFromEris;

                if (baseUrl) {
                    dispatch(setBaseUrl(baseUrl));
                    fetchBackendConfigurations(baseUrl);
                }
            })
    }

    const fetchBackendConfigurations = (baseUrl: string) => {
        const authConfig: HttpConfig = {
            accessToken: dcbAccessToken,
            baseUrl
        }

        if (subscription) {
            subscription.unsubscribe();
        }

        setSubscription(
            forkJoin([getBootstrap(authConfig), getSession(authConfig), getCustomTranslation(authConfig, appCurrentLanguage)])
                .subscribe((data) => {
                    const boostrap = data[0];
                    const session = data[1];
                    const customTranslations = data[2];

                    const userNeedToAcceptPolicy = !!session.need_policy_accept;
                    const userNeedToAcceptTermsAndConditions = !!session.need_terms_and_conditions_accept;

                    if (azureAccessToken) {
                        setCachedAccessToken(azureAccessToken, dcbAccessToken, session.id).subscribe();
                    }

                    dispatch(updateBootstrap(boostrap));
                    dispatch(updateSession(session));
                    dispatch(updateUserNeedToAcceptPolicy(userNeedToAcceptPolicy));
                    dispatch(updateUserNeedToAcceptTermsAndConditions(userNeedToAcceptTermsAndConditions));

                    dispatch(updateUserLanguageInLMS(session.language));
                    dispatch(updateCustomTranslations(customTranslations));

                    dispatch(setIsLogged(boostrap.is_logged_in));
                    dispatch(resetAuthenticationError());
                })
        )
    }

    const openAuthorizationAppPopup = () => {
        dispatch(resetAuthenticationError());
        setIsLoading(true);
        getMicrosoftAccessToken();
    }

    const getBlankSlate = () => {
        switch (authError) {
            case AuthErrorEnum.UNREGISTERED_TENANT:
                return (<Flex
                    hAlign="center"
                    vAlign="center"
                    column>
                    <AteBlankSlate
                        title={{
                            type: WidgetTranslationTypeEnum.SINGLE,
                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateWelcome),
                        }}
                        subtitle={{
                            type: WidgetTranslationTypeEnum.SINGLE,
                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateContactYourAdministratorToConnectYourLmsToMicrosoftTeams),
                        }}
                        illustrationName='on-the-way'
                    />
                    <Flex
                        hAlign="center"
                        vAlign="center"
                        gap="gap.large"
                    >
                        <AteButtonRaised
                            isActive={true}
                            content={AteLocalize.getLocalizedLabel(DICTIONARY.connect)}
                            onClick={() => {
                                onClickConnect()
                            }}
                        />
                        <AteButtonRaised
                            isActive={true}
                            content={AteLocalize.getLocalizedLabel(DICTIONARY.learnMore)}
                            onClick={() => {
                                onClickLearnMore()
                            }}
                        />
                    </Flex>
                </Flex>);
            case AuthErrorEnum.USER_NOT_FOUND:
                return (<AteBlankSlate
                    title={{
                        type: WidgetTranslationTypeEnum.SINGLE,
                        value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateAccessDenied),
                    }}
                    subtitle={{
                        type: WidgetTranslationTypeEnum.SINGLE,
                        value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlatePleaseContactYourAdministratorToCheckYourDashboardVisibility),
                    }}
                    illustrationName='denied'
                />);
            case AuthErrorEnum.UNAUTHORIZED_APP:
                return (<Flex
                    hAlign="center"
                    vAlign="center"
                    column>
                    <AteBlankSlate
                        title={{
                            type: WidgetTranslationTypeEnum.SINGLE,
                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateMicrosoftSignIn),
                        }}
                        subtitle={{
                            type: WidgetTranslationTypeEnum.SINGLE,
                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateSignInWithMicrosoftToStartUsingTheApp),
                        }}
                        illustrationName='hello'
                    />
                    <Button
                        onClick={openAuthorizationAppPopup}
                    >
                        {AteLocalize.getLocalizedLabel(DICTIONARY.signInWithMicrosoft)}
                    </Button>
                </Flex>);
            default:
                return (<></>);
        }
    }

    const onClickConnect = () => {
        app.openLink(`https://teams.microsoft.com/l/entity/${process.env.REACT_APP_MANIFEST_APP_ID}/conversations`)
    }

    const onClickLearnMore = () => {
        app.openLink('https://help.docebo.com/hc/en-us/articles/14039418936210-Docebo-for-Microsoft-Teams')
    }

    return (
        <Flex
            className="login-page"
            gap="gap.small"
            hAlign="center"
            vAlign="center"
            column>
            {
                isLoading || isLogged ?
                    <Spinner /> :
                    getBlankSlate()
            }
        </Flex>
    );
}

