import { sharing } from '@microsoft/teams-js';
import { ICourseSharingInfo } from 'intefaces/sharing.interface';

export const TASK_MODULE_MODE_PARAM = 'modal';

export const openCourseSharingDialog = (courseInfo: ICourseSharingInfo): void => {
    sharing.shareWebContent({
        content: [
            {
                type: 'URL',
                url: getDeepLinkTaskModule(courseInfo.id),
                preview: true
            }
        ]
    });
}

export const getDeepLinkTaskModule = (courseId: number) => {
    const coursePlayerPageURL = new URL(`https://${process.env.REACT_APP_ATE_BUCKET_URL}/course-player/${courseId}`);
    coursePlayerPageURL.searchParams.append(TASK_MODULE_MODE_PARAM, 'true');

    const taskModuleUrl = new URL(`https://teams.microsoft.com/l/task/${process.env.REACT_APP_MANIFEST_APP_ID}`);
    getTaskModuleSearchParams({ courseId, coursePlayerPageURL }).forEach(param => taskModuleUrl.searchParams.append(param.name, param.value));

    return taskModuleUrl.toString();
}

const getTaskModuleSearchParams = (payload: { courseId: number, coursePlayerPageURL: URL }): Array<{ name: string, value: string }> => {
    return [
        {
            name: 'courseId',
            value: payload.courseId.toString()
        },
        {
            name: 'height',
            value: 'large'
        },
        {
            name: 'width',
            value: 'large'
        },
        {
            name: 'url',
            value: payload.coursePlayerPageURL.toString()
        }
    ]
}
