export interface ICourseInfo {
    id: number;
    name: string;
    slug_name: string;
    current_rating: number;
    background_image: IBackgroundImage;
    has_autoplay: boolean;
    has_overview: boolean;
    show_toc: boolean;
    status: CourseStatusEnum;
    on_sale: boolean;
    type: CourseTypeEnum;
    enrolled: IEnrolled;
    can_self_enroll: boolean;
    thumbnail?: string;
    can_enter: boolean;
    lang_code: string;
    duration: number;
    is_rated: boolean;
    description: string;
    enter_status: IEnterStatus;
    can_enter_reason?: string;
    enrollment_date_end?: string;
    course_date_end: string;
    enrollment_date_start?: string;
    soft_deadline?: string;
    course_date_start: string;
    level?: string;
}

export interface IEnterStatus {
    status: CourseEnterStatus;
    price: number;
}

export interface IBackgroundImage {
    url?: string;
    aspect_ratio: string;
}

export interface IEnrolled {
    is_enrolled: boolean;
    waiting_list: boolean;
    enrollment_fields_completed: boolean;
}

export enum CourseTypeEnum {
    E_LEARNING = 'elearning',
    WEBINAR = 'webinar',
    CLASSROOM = 'classroom',
    LEARNING_PLAN = 'learning_plan',
}

export enum CourseStatusEnum {
    ENROLLED = 'enrolled',
    NOT_STARTED = 'not_started',
    SUBSCRIBED = 'subscribed',
    NOT_SUBSCRIBED = 'non_subscribed',
    IN_PROGRESS = 'in_progress',
    IN_PROGRESS_WITH_SPACE = 'in progress',
    IN_PROGRESS_LP = 'in-progress',
    COMPLETED = 'completed',
    DONE = 'done',
    WAITING = 'waiting',
    CONFIRM_SUBSCRIPTION = 'subscription_to_confirm',
    SUSPENDED = 'suspended',
    OVERBOOKING = 'overbooking',
}

export enum CourseEnterStatus {
    PLAY = 'play',
    ENROLL = 'enroll',
    BUY = 'buy',
    LOCKED = 'locked',
    LOCKED_SOFT_DEADLINE = 'locked_soft_deadline',
    ENROLL_WAITING_LIST = 'in_waiting_list_enroll',
    WAITING_LIST_PLAY = 'in_waiting_list_play',
    BUY_WAITING_LIST = 'in_waiting_list'
}
