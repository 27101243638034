import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { BREADCRUMB_EPICS } from './breadcrumb/breadcrumb.epics';
import { RootState } from './reducers';
import { TRANSLATIONS_EPICS } from './translations/translations.epics';

export const rootEpic: AteEpics = combineEpics(
    ...BREADCRUMB_EPICS,
    ...TRANSLATIONS_EPICS
)

export type AteEpics = Epic<AnyAction, AnyAction, RootState>;

export const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();
