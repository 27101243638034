import AteLocalizable from "services/translations/AteLocalizable"

const DCB_FOR_MSTEAMS_MODULE = 'microsoft_teams';

export const DICTIONARY = {
    blankSlateNothingHereYet: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, `Whoops, there’s nothing here yet`),
    blankSlatelooksLikeYourAdminIsStillSettingUpYourDashboard: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, `Looks like your Administrator is still setting up your dashboard.`),
    blankSlateYouAreAlmostThere: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'You are almost there'),
    blankSlateClickTheSaveButtonToAddTheTrainingDashboardTab: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Click the Save button to add the training dashboard tab.'),
    blankSlateWelcome: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Welcome!'),
    blankSlateContactYourAdministratorToConnectYourLmsToMicrosoftTeams: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Contact your Administrator to connect your LMS to Microsoft Teams'),
    blankSlateAccessDenied: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Access Denied'),
    blankSlatePleaseContactYourAdministratorToCheckYourDashboardVisibility: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Please contact your administrator to check your dashboard visibility'),
    blankSlateMicrosoftSignIn: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Microsoft sign-in'),
    blankSlateSignInWithMicrosoftToStartUsingTheApp: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Sign in with Microsoft to start using the app.'),
    blankSlateYouDoNotYetPermissionToAccessThisSection: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'You do not yet have permission to access this section. Make sure you have accepted all necessary Terms of Use to proceed.'),
    blankSlateWhoopsThereSNothingHere: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, `Whoops, there’s nothing here`),
    blankSlateLooksLikeThereSNothingForYouToDoInThisAreaRightNow: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, `Looks like there’s nothing for you to do in this area right now`),
    blankSlateWhoopsNoResultFound: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Whoops, no result found!'),
    blankSlatePleaseTryAgainUsingDifferentSearchCriteriaOrFilters: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Please try again using different search criteria or filters'),
    connect: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Connect'),
    learnMore: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Learn More'),
    signInWithMicrosoft: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Sign in with Microsoft'),
    okGotIt: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'OK, GOT IT'),
    waitingForApprovalDialog: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Waiting for Approval'),
    waitingForApprovalDialogSubtitle: (courseName?: string) => {
        return courseName ?
            new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'The course {coursetitle} in which you have been enrolled requires approval. Please contact your manager or administrator for more information.', { coursetitle: courseName }) :
            new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'The course in which you have been enrolled requires approval. Please contact your manager or administrator for more information.')
    },
    goToTermsOfUse: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Go to Terms of Use'),
    viewAll: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'View all'),
    filter: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Filter'),
    newestToOldest: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Newest to Oldest (Enrollment)'),
    find: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Search'),
    shareCourse: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Share course'),
    youMustReadAndAgreeToTheNewPrivacyPolicyInOrderToProceed: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'You must read and agree to the new Privacy Policy in order to proceed'),
    youMustReadAndAgreeToThePrivacyPolicyInOrderToProceed: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'You must read and agree to the Privacy Policy in order to proceed'),
    iDonTAgree: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, "I DON'T AGREE"),
    iAgree: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'I AGREE'),
    youMustReadAndAgreeToTheTermsConditionsInOrderToProceed: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'You must read and agree to the Terms & Conditions in order to proceed'),
    required: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, '(Required)'),
    myCoursesLearningPlans: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'My Courses & Learning Plans'),
    dashboard: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Dashboard'),
    login: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Login'),
    auth: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Auth'),
    coursePlayer: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Course Player'),
    learningPlan: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Learning Plan'),
    privacyPolicy: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Privacy Policy'),
    configurableTabsSettings: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Configurable Tabs Settings'),
    staticCourseList: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Static Course List'),
    notStarted: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Not Started'),
    inProgress: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'In Progress'),
    completed: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Completed'),
    nameAZ: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Name A-Z'),
    nameZA: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Name Z-A'),
    codeAZ: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Code A-Z'),
    codeZA: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Code Z-A'),
    oldestToNewestEnrollment: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Oldest to Newest (Enrollment)'),
    waitingList: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Waiting List'),
    eLearning: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'E-learning'),
    webinar: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Webinar'),
    classroom: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Classroom'),
    clear: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Clear'),
    courseCurrentlyNotAvailable: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Course currently not available'),
    instructor: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Instructor'),
    hidden: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Hidden'),
    outdated: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Outdated'),
    expired: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Expired'),
    prerequisitesNotSatisfied: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Prerequisites Not Satisfied'),
    enrollmentStatusSuspended: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Enrollment status: suspended'),
    enrollmentStatusOverbooking: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Enrollment status: overbooking'),
    activeFrom: (date: string) => new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Active from: {date}', { date }),
    overdue: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'overdue'),
    expiringOn: (date: string) => new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'Expiring on {date}', { date }),
    course: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'course'),
    courses: new AteLocalizable(DCB_FOR_MSTEAMS_MODULE, 'courses'),
}

