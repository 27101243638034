import { Spinner } from '@fluentui/react-components';
import React, { useEffect, useRef } from "react"
import './AteInfiniteScroll.scss';
import { IAteInfiniteScroll } from "./interfaces/infiniteScroll.interfaces";

export default function AteInfiniteScroll(props: IAteInfiniteScroll) {
    const loader = useRef<HTMLDivElement>(null);
    const { isLoading, hasMoreData, loadMore } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleObserver = (entities: IntersectionObserverEntry[]) => {
        const target = entities[0];

        if (target.isIntersecting && !isLoading && hasMoreData) {
            loadMore();
        }
    };

    useEffect(() => {
        const options: IntersectionObserverInit = {
            root: null,
            rootMargin: "0px",
            threshold: 0
        }

        const observer = new IntersectionObserver(handleObserver, options);

        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => {
            if (loader.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(loader.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleObserver])

    return (
        <div
            className={'infinite-scroll'}>
            {props.children}

            <div
                ref={loader}
                className={'loader'}>
                {
                    isLoading &&
                    <Spinner
                        size={'small'}
                    />
                }
            </div>
        </div>
    )
}
