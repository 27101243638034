import { Spinner } from '@fluentui/react-components';
import { IAteBreadcrumbItem } from "components/shared/breadcrumb/interfaces/breadcrumb.interface";
import AteStaticCourseListUngroupedView from "components/widgets/staticCourseList/ungroupedView/AteStaticCourseListUngroupedView";
import { getWidgetTranslatedLabel } from "helpers/translations/translations.helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageInfoEnum, PAGES_INFO } from "routes/pageSettings";
import { AteLocalize } from "services/translations/AteLocalize";
import { getStaticCourseListPageSettings } from "store/app/app.selectors"
import { getDcbLmsDefaultLanguage } from "store/bootstrap/boostrap.selector";
import { updateBreadcrumbItems } from "store/breadcrumb/breadcrumb.slice";
import { getAppCurrentLanguage } from "store/translations/translations.selector";
import './StaticCourseListPage.scss';

export default function StaticCourseListPage() {
    const dispatch = useDispatch();

    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);
    const staticCourseListPageSettings = useSelector(getStaticCourseListPageSettings);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!!staticCourseListPageSettings) {
            setBreadcrumb();
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staticCourseListPageSettings]);

    const setBreadcrumb = () => {
        const previousHistory: IAteBreadcrumbItem[] = [
            PAGES_INFO[PageInfoEnum.DASHBOARD]
        ];

        if (staticCourseListPageSettings) {
            const title = staticCourseListPageSettings.title ?
                getWidgetTranslatedLabel(staticCourseListPageSettings.title, userLanguage, dcbLmsDefaultPlatformLanguage) :
                AteLocalize.getLocalizedLabel(PAGES_INFO[PageInfoEnum.STATIC_COURSE_LIST].title);

            previousHistory.push({
                path: PAGES_INFO[PageInfoEnum.STATIC_COURSE_LIST].path,
                title
            });
        }

        dispatch(
            updateBreadcrumbItems({
                items: [
                    ...previousHistory
                ]
            })
        );
    };

    return (
        <div className="ate-static-course-list-page">
            <div className="ui-row">
                {
                    isLoading ?
                        <Spinner className={'spinner'} /> :
                        staticCourseListPageSettings ?
                            <AteStaticCourseListUngroupedView {
                                ...{
                                    ...staticCourseListPageSettings,
                                    usedInSCLPage: true
                                }
                            } /> :
                            <></>
                }
            </div>
        </div>
    )
}
