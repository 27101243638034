import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';
import {
    Divider,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    Badge, MenuButton
} from '@fluentui/react-components';
import { enrollmentUserInCourse, getCourse } from 'api/BackendCalls';
import {
    getContentLanguageCodeFromEndpointLanguage,
    getCourseBadgeStatusInfo,
    getCourseTypeLabel,
    getDefaultCourseThumbnail,
    getFormattedDuration,
    getCourseIcon,
    isCourseAccessible
} from 'helpers/course.helpers';
import { ICourseInfo } from 'intefaces/course.interface';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PageInfoEnum, PAGES_INFO } from 'routes/pageSettings';
import { getBaseUrl, getDcbAccessToken } from 'store/auth/auth.selectors';
import { getActiveLanguages } from 'store/bootstrap/boostrap.selector';
import { getUserId } from 'store/session/session.selector';
import { IAteSingleCoursePlayerPreviewWidget } from '../interface/coursePlayer.interface';
import './AteSingleCoursePlayerPreview.scss';
import { AteIcon } from 'components/shared/icon/AteIcon';
import { IconColorEnum } from 'components/shared/icon/helpers/icon-color';
import { IconSizeEnum } from 'components/shared/icon/helpers/icon-size';
import { getIsCourseSharingEnabled } from 'store/app/app.selectors';
import { filter } from 'rxjs/operators';
import { openCourseSharingDialog } from 'helpers/sharing.helpers';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';
import { MoreHorizontalRegular } from '@fluentui/react-icons';
import { IMenuItem } from 'components/shared/cards/interfaces/card.interface';

export default function AteSingleCoursePlayerPreview(props: IAteSingleCoursePlayerPreviewWidget) {
    const courseId = props.course_id;
    const autoplay = props.autoplay;
    const accessToken = useSelector(getDcbAccessToken);
    const baseUrl = useSelector(getBaseUrl);
    const userId = useSelector(getUserId);
    const activeLanguages = useSelector(getActiveLanguages);
    const isCourseSharingEnabled = useSelector(getIsCourseSharingEnabled);

    const [courseInfo, setCourseInfo] = useState<ICourseInfo>();
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        getCourse({ accessToken, baseUrl }, courseId)
            .pipe(
                filter(course => isCourseAccessible(course))
            )
            .subscribe(res => {
                if (res) {
                    setCourseInfo(res)
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId])

    const getBackgroundImage = (): string => {
        if (!courseInfo) {
            return '';
        }

        return courseInfo.background_image.url ??
            courseInfo.thumbnail ??
            getDefaultCourseThumbnail(courseInfo.type, baseUrl);
    }

    const getBackgroundImageCssRule = () => {
        return { '--course-background-image': `url('${getBackgroundImage()}')` } as React.CSSProperties;
    }

    const handleCourseClick = (): void => {
        if (courseInfo?.enrolled.waiting_list) {
            return;
        }

        if (courseInfo?.enrolled.is_enrolled) {
            navigateToCoursePlayer()
        } else if (courseInfo?.can_self_enroll) {
            enrollmentUserInCourse({ accessToken, baseUrl }, courseId, userId)
                .subscribe(() => {
                    getCourse({ accessToken, baseUrl }, courseId)
                        .subscribe(courseInfo => {
                            setCourseInfo(courseInfo);
                            if (courseInfo.enrolled.waiting_list) {
                                setOpenDialog(true);
                            } else {
                                navigateToCoursePlayer();
                            }
                        });
                })
        }
    };

    const navigateToCoursePlayer = () => {
        history.push({
            pathname: PAGES_INFO[PageInfoEnum.COURSE_PLAYER].path,
            state: { id: courseId, autoplay }
        });
    }

    const shareCourse = (event: React.SyntheticEvent<HTMLElement, Event>) => {
        event.preventDefault();
        event.stopPropagation();
        if (courseInfo) {
            openCourseSharingDialog({ id: courseInfo.id, name: courseInfo.name })
        }
    }

    const getMenu = () => {
        const items: IMenuItem[] = [];

        console.log('isCourseSharingEnabled? ', isCourseSharingEnabled);

        if (isCourseSharingEnabled) {
            items.push({
                key: 'share',
                content: AteLocalize.getLocalizedLabel(DICTIONARY.shareCourse),
                onClick: (e: SyntheticEvent<HTMLElement, Event>) => shareCourse(e),
            })
        }

        return items.length ?
            (
                <Menu>
                    <MenuTrigger>
                        <MenuButton icon={<MoreHorizontalRegular/>}
                                    appearance="subtle"
                                    onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                                        e.stopPropagation();
                                    }}>
                        </MenuButton>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {items.map(item =>
                                <MenuItem
                                    key={item.key}
                                    onClick={item.onClick}>
                                    {item.content}
                                </MenuItem>
                            )}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            ) : <></>
    }

    return (
        <>
            {
                courseInfo &&
                <Flex
                    style={
                        {
                            ...getBackgroundImageCssRule(),
                            minHeight: props.min_height
                        }
                    }
                    data-testid="single-course-player-preview"
                    className={'single-course-player-preview'}
                    vAlign={'end'}
                    onClick={handleCourseClick}
                >
                    <Flex
                        className={'menu'}>
                        {
                            getMenu()
                        }
                    </Flex>

                    <Flex
                        column
                        className={'course-info'}>
                        {
                            courseInfo.enrolled.is_enrolled && getCourseBadgeStatusInfo(courseInfo.status) &&
                            <div
                                className={'course-status'}>
                                {getCourseBadgeStatusInfo(courseInfo.status).label ?
                                    <Badge appearance="filled"
                                           shape="rounded"
                                           color={getCourseBadgeStatusInfo(courseInfo.status).color}>
                                        <StyledText size={'small'}>
                                            {getCourseBadgeStatusInfo(courseInfo.status).label}
                                        </StyledText>
                                    </Badge> :
                                    <></>
                                }
                            </div>
                        }

                        <div
                            className={'course-name'}
                        >
                            <StyledText
                                size={'larger'}
                                weight={'bold'}
                            >
                                {courseInfo.name}
                            </StyledText>
                        </div>

                        <Flex
                            className={'course-other-info'}
                            vAlign={'center'}>
                            <StyledText
                                size={'small'}
                                weight={'semibold'}
                            >
                                {getContentLanguageCodeFromEndpointLanguage(courseInfo.lang_code, activeLanguages)}
                            </StyledText>
                            {
                                courseInfo.duration ?
                                    <>
                                        <Divider
                                            className={'divider'}
                                            vertical
                                        />
                                        <StyledText
                                            size={'small'}
                                            weight={'semibold'}
                                        >
                                            {getFormattedDuration(+courseInfo.duration)}
                                        </StyledText>
                                    </> : ''
                            }
                            {
                                courseInfo.current_rating ?
                                    <Flex
                                        className={'course-rating'}
                                        vAlign={'center'}>
                                        <AteIcon
                                            size={IconSizeEnum.XS}
                                            name={'star-filled'}
                                            color={IconColorEnum.NEGATIVE}
                                        />
                                        <StyledText
                                            className={'rating'}
                                            size={'small'}
                                            weight={'semibold'}
                                        >
                                            {courseInfo.current_rating}
                                        </StyledText>
                                    </Flex> : ''
                            }
                        </Flex>

                        <Flex
                            className={'course-type'}
                            vAlign={'center'}>
                            <AteIcon
                                size={IconSizeEnum.XS}
                                name={getCourseIcon(courseInfo.type)}
                                color={IconColorEnum.NEGATIVE}
                            />
                            <StyledText
                                className={'course-type-label'}
                            >
                                {getCourseTypeLabel(courseInfo.type)}
                            </StyledText>
                        </Flex>
                    </Flex>
                </Flex>
            }

            {
                courseInfo &&
                <Dialog
                    modalType="alert"
                    open={openDialog}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>
                                {AteLocalize.getLocalizedLabel(DICTIONARY.waitingForApprovalDialog)}
                            </DialogTitle>
                            <DialogContent>
                                {AteLocalize.getLocalizedLabel(DICTIONARY.waitingForApprovalDialogSubtitle(courseInfo.name))}
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger>
                                    <Button onClick={() => setOpenDialog(false)}>
                                        {AteLocalize.getLocalizedLabel(DICTIONARY.okGotIt)}
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            }
        </>
    )
}
