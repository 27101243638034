import './AteBreadcrumb.scss';
import { useSelector } from 'react-redux';
import { getBreadcrumb } from 'store/helpers';
import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbDivider, BreadcrumbItem, BreadcrumbButton, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, MenuProps, MenuButton, Tooltip, Button } from '@fluentui/react-components';
import { useHistory } from 'react-router-dom';
import { IAteBreadcrumbItem, TitleCharactersLimit } from 'components/shared/breadcrumb/interfaces/breadcrumb.interface';
import { MDZone, XSZone } from 'helpers/viewportBreakpoint.helpers';
import { AteLocalize } from 'services/translations/AteLocalize';
import AteBreadcrumbFilters from './filters/AteBreadcrumbFilters';
import { CheckmarkFilled, ChevronDownRegular, MoreHorizontalRegular } from "@fluentui/react-icons";
import classNames from 'classnames';
import _ from 'lodash';

export default function AteBreadcrumb() {
    const breadcrumbsSettings = useSelector(getBreadcrumb);
    const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
    const history = useHistory();
    const isXSZone = XSZone();
    const isMDZone = MDZone();

    const breadcrumbContainerClasses = classNames({
        'breadcrumb-desktop': !isXSZone,
        'breadcrumb-mobile': isXSZone,
        'has-filters': breadcrumbsSettings.showFilters,
        'full-width': !breadcrumbsSettings.showFilters && isXSZone
    });

    const navigateToItemSelected = (item: IAteBreadcrumbItem): void => {
        if (!item.isItemSelected)
            history.push(item.path);
    };

    const onBreadcrumbMobileOpenChange: MenuProps["onOpenChange"] = (e, data) => {
        setMobileDropdownOpen(data.open);
    };


    /**
     * A text with more than 20 chars is considered a long text
     */

    const isTitleLong = (title: string): boolean => {
        return title.length > 20;
    }

    /**
     * Using CSS rules for text truncation would hide the button icon for the breadcrumb title/items on mobile.
     */

    const truncateBreadcrumbText = (title: string, maxLength: TitleCharactersLimit, isSpaceLimited = true) => {
        return isSpaceLimited && title.length > maxLength ? title.slice(0, maxLength - 1) + '\u2026' : title;
    }

    const getBreadcrumbDesktop = () => {
        return (
            <Breadcrumb>
                {breadcrumbsSettings.items.map((item) => (
                    <>
                        <BreadcrumbItem className={item.isItemSelected ? 'breadcrumb-item-selected' : ''}>
                            {!item.isItemSelected && isTitleLong(AteLocalize.getLocalizedLabel(item.title) as string) ?
                                <Tooltip
                                    withArrow
                                    appearance="inverted"
                                    content={AteLocalize.getLocalizedLabel(item.title) as string}
                                    relationship="label">
                                    <BreadcrumbButton
                                        className={'breadcrumb-button'}
                                        current={item.isItemSelected}
                                        onClick={() => navigateToItemSelected(item)}>
                                        {truncateBreadcrumbText(AteLocalize.getLocalizedLabel(item.title), TitleCharactersLimit.SM)}
                                    </BreadcrumbButton>
                                </Tooltip>
                                :
                                <BreadcrumbButton
                                    className={item.isItemSelected ? 'breadcrumb-button-selected' : 'breadcrumb-button'}
                                    current={item.isItemSelected}
                                    onClick={() => navigateToItemSelected(item)}>
                                    {item.isItemSelected ? AteLocalize.getLocalizedLabel(item.title) : truncateBreadcrumbText(AteLocalize.getLocalizedLabel(item.title), TitleCharactersLimit.SM)}
                                </BreadcrumbButton>
                            }
                        </BreadcrumbItem>
                        {!item.isItemSelected ? <BreadcrumbDivider /> : <></>}
                    </>
                ))}
            </Breadcrumb>
        )
    }

    /**
     * When the breadcrumb items are less than 4 and the filter section is not visible, renders the full list.
     * Otherwise, groups all the items inside the menu, except for the selected one
     */
    const getBreadcrumbTabletMenu = () => {
        if (breadcrumbsSettings.items.length < 4 && !breadcrumbsSettings.showFilters) {
            return getBreadcrumbTabletFull();
        }

        const lastItem = _.last(breadcrumbsSettings.items);
        return (
            <Breadcrumb>
                <>
                    <BreadcrumbItem>
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <MenuButton
                                    shape="circular"
                                    appearance="subtle"
                                    icon={<MoreHorizontalRegular/>}
                                ></MenuButton>
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    {breadcrumbsSettings.items.map((item) => (
                                        !item.isItemSelected &&
                                        <MenuItem
                                            onClick={() => navigateToItemSelected(item)}>
                                            {truncateBreadcrumbText(AteLocalize.getLocalizedLabel(item.title), TitleCharactersLimit.MD)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </BreadcrumbItem>
                    <BreadcrumbDivider/>
                </>

                {lastItem?.isItemSelected &&
                    <>
                        <BreadcrumbItem className={'breadcrumb-item-selected'}>
                            <BreadcrumbButton
                                className={'breadcrumb-button-selected'}
                                current={lastItem?.isItemSelected}
                                onClick={() => navigateToItemSelected(lastItem)}
                            >
                                {AteLocalize.getLocalizedLabel(lastItem.title)}
                            </BreadcrumbButton>
                        </BreadcrumbItem>
                        {!lastItem.isItemSelected ? <BreadcrumbDivider /> : <></>}
                    </>
                }
            </Breadcrumb>
        )
    }

    const getBreadcrumbTabletFull = () => {
        return (
            <Breadcrumb>
                {breadcrumbsSettings.items.map((item) => (
                    <>
                        <BreadcrumbItem className={item.isItemSelected ? 'breadcrumb-item-selected' : ''}>
                            {!item.isItemSelected && isTitleLong(AteLocalize.getLocalizedLabel(item.title) as string) ?
                                <Tooltip
                                    withArrow
                                    appearance="inverted"
                                    content={AteLocalize.getLocalizedLabel(item.title) as string}
                                    relationship="label">
                                    <BreadcrumbButton
                                        current={item.isItemSelected}
                                        onClick={() => navigateToItemSelected(item)}>
                                        {truncateBreadcrumbText(AteLocalize.getLocalizedLabel(item.title), TitleCharactersLimit.XS)}
                                    </BreadcrumbButton>
                                </Tooltip>
                                :
                                <BreadcrumbButton
                                    className={item.isItemSelected ? 'breadcrumb-button-selected' : ''}
                                    current={item.isItemSelected}
                                    onClick={() => navigateToItemSelected(item)}>
                                    {item.isItemSelected ? AteLocalize.getLocalizedLabel(item.title) : truncateBreadcrumbText(AteLocalize.getLocalizedLabel(item.title), TitleCharactersLimit.XS)}
                                </BreadcrumbButton>
                            }
                        </BreadcrumbItem>
                        {!item.isItemSelected ? <BreadcrumbDivider /> : <></>}
                    </>
                ))}
            </Breadcrumb>
        )
    }

    const getBreadcrumbMobile = () => {
        const itemSelected =
            breadcrumbsSettings.items.find(i => i.isItemSelected) ??
            breadcrumbsSettings.items[breadcrumbsSettings.items.length - 1];

        return (
            <Menu open={mobileDropdownOpen}
                onOpenChange={onBreadcrumbMobileOpenChange}>
                <MenuTrigger disableButtonEnhancement>
                    <Button icon={<ChevronDownRegular/>}
                            iconPosition="after"
                            className={'item-selected-button'}>
                        {truncateBreadcrumbText(AteLocalize.getLocalizedLabel(itemSelected.title), !!breadcrumbsSettings.showFilters ? TitleCharactersLimit.SM : TitleCharactersLimit.LG)}
                    </Button>
                </MenuTrigger>
                <MenuPopover className={`${!breadcrumbsSettings.showFilters ? 'full-width-mobile-menu' : ''}`}>
                    <MenuList>
                        {
                            breadcrumbsSettings.items.map((item) => {
                                return (
                                    <MenuItem
                                        onClick={() => navigateToItemSelected(item)}
                                        className={'menu-item'}>
                                        <div className={'item-container'}>
                                            <span className={'item-content'}>
                                                {truncateBreadcrumbText(AteLocalize.getLocalizedLabel(item.title), TitleCharactersLimit.LG)}
                                            </span>
                                            <span className={'item-icon'}>
                                                {item.isItemSelected ? <CheckmarkFilled /> : ''}
                                             </span>
                                        </div>
                                    </MenuItem>
                                )
                            })
                        }
                    </MenuList>
                </MenuPopover>
            </Menu>
        )
    }

    return (
        <div
            className={
                `ate-breadcrumb ${!breadcrumbsSettings.showBreadcrumb && 'hidden'}`
            }
        >
            {breadcrumbsSettings.showBreadcrumb &&
                <div
                    className={breadcrumbContainerClasses}
                >
                    <div className="navigation">
                        {isXSZone ? getBreadcrumbMobile() : isMDZone ? getBreadcrumbTabletMenu() : getBreadcrumbDesktop()}
                    </div>
                    {
                        breadcrumbsSettings.showFilters &&
                        <div className="filters">
                            <AteBreadcrumbFilters />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

