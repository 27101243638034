import { AteBlankSlate } from 'components/shared/blankSlate/AteBlankSlate';
import { WidgetTranslationTypeEnum } from 'intefaces/commons.inteface';
import { useEffect } from 'react';
import { PageInfoEnum, PAGES_INFO } from 'routes/pageSettings';
import './ConfigurableTabSettingsPage.scss';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';
import { pages } from '@microsoft/teams-js';

export default function ConfigurableTabSettingsPage() {

    useEffect(() => {
        pages.config.setValidityState(true);
        pages.config.registerOnSaveHandler((saveEvent) => {
            pages.config.setConfig({
                contentUrl: `https://${process.env.REACT_APP_ATE_BUCKET_URL}${PAGES_INFO[PageInfoEnum.DASHBOARD].path}`,
                entityId: "dashboardTab",
                suggestedDisplayName: "Training Dashboard"
            }).then(() => saveEvent.notifySuccess())
        });
    }, [])

    return (
        <div className='configurable-tab-settings'>
            <AteBlankSlate
                title={{
                    type: WidgetTranslationTypeEnum.SINGLE,
                    value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateYouAreAlmostThere),
                }}
                subtitle={{
                    type: WidgetTranslationTypeEnum.SINGLE,
                    value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateClickTheSaveButtonToAddTheTrainingDashboardTab),
                }}
                illustrationName='well-done'
            />
        </div>
    )
}
