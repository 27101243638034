import { StyledText, Segment, Flex } from "@fluentui/react-migration-v0-v9";
import { AteIcon } from "components/shared/icon/AteIcon";
import { IconSizeEnum } from "components/shared/icon/helpers/icon-size";
import { getWidgetTranslatedLabel } from "helpers/translations/translations.helpers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageInfoEnum, PAGES_INFO } from "routes/pageSettings";
import { updateStaticCourseListPage } from "store/app/app.slice";
import { IAteStaticCourseListWidget } from "../interfaces/staticCourseList.interface";
import './AteStaticCourseListGroupedView.scss';
import { getAppCurrentLanguage } from "store/translations/translations.selector";
import { getDcbLmsDefaultLanguage } from "store/bootstrap/boostrap.selector";

export default function AteStaticCourseListGroupedView(props: IAteStaticCourseListWidget) {
    const dispatch = useDispatch();
    const history = useHistory();

    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);

    const onClickHandle = () => {
        dispatch(updateStaticCourseListPage(props));
        history.push(PAGES_INFO[PageInfoEnum.STATIC_COURSE_LIST].path);
    }

    return (
        <Segment
            data-testid="ate-static-course-list-grouped-view"
            className="ate-static-course-list-grouped-view"
            style={{ minHeight: props.min_height }}
            onClick={() => onClickHandle()}
        >
            <Flex
                className="icon-container"
                style={{ background: props.background_colour }}>
                <AteIcon
                    size={IconSizeEnum.MD}
                    name={'courses'}
                    customColor={props.icon_colour}
                />
            </Flex>
            <Flex
                className="title-container"
                column>
                <StyledText
                    weight={'bold'}
                    className={'title'}
                >
                    {props.title ? getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage) : ''}
                </StyledText>
                {
                    props.subtitle &&
                    <StyledText
                        size={'medium'}
                        className={'subtitle'}
                    >
                        {getWidgetTranslatedLabel(props.subtitle, userLanguage, dcbLmsDefaultPlatformLanguage)}
                    </StyledText>
                }
            </Flex>
        </Segment>
    )
}
