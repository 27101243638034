import {
    CONFIGURABLE_TABS_TITLE,
    COURSE_PLAYER,
    DASHBOARD_TITLE,
    LEARNING_PLAN,
    LOGIN_TITLE,
    MY_COURSES_AND_LP_TITLE,
    PRIVACY_POLICY,
    STATIC_COURSE_LIST,
} from 'helpers/translations/labels';
import AteLocalizable from 'services/translations/AteLocalizable';

export enum PageInfoEnum {
    DASHBOARD,
    MY_COURSES_AND_LP,
    COURSE_PLAYER,
    LOGIN,
    LEARNING_PLAN,
    POLICIES,
    CONFIGURABLE_TABS,
    STATIC_COURSE_LIST
}

export interface IPageInfo {
    path: string,
    title: string | AteLocalizable,
}

export const PAGES_INFO = {
    [PageInfoEnum.DASHBOARD]: {
        path: '/dashboard',
        title: DASHBOARD_TITLE,
    },
    [PageInfoEnum.MY_COURSES_AND_LP]: {
        path: '/my-courses-and-learning-plans',
        title: MY_COURSES_AND_LP_TITLE,
    },
    [PageInfoEnum.COURSE_PLAYER]: {
        path: '/course-player/:id',
        title: COURSE_PLAYER,
    },
    [PageInfoEnum.LOGIN]: {
        path: '/login',
        title: LOGIN_TITLE,
    },
    [PageInfoEnum.LEARNING_PLAN]: {
        path: '/learning-plan/:id',
        title: LEARNING_PLAN,
    },
    [PageInfoEnum.POLICIES]: {
        path: '/policies',
        title: PRIVACY_POLICY,
    },
    [PageInfoEnum.CONFIGURABLE_TABS]: {
        path: '/config-tabs',
        title: CONFIGURABLE_TABS_TITLE
    },
    [PageInfoEnum.STATIC_COURSE_LIST]: {
        path: '/static-course-list',
        title: STATIC_COURSE_LIST
    }
}
