import { useLocation } from "react-router-dom";

export const getHostnameFromUrl = (baseUrl: string): string => {
    const url = new URL(baseUrl);
    return url.hostname || '';
}

export const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search);
}
