import _ from "lodash";
import { useLayoutEffect, useState } from "react";
import { Observable } from "rxjs";

export const isClickingOutsideElement = (ref: any) => {
    return new Observable(observer => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                observer.next()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
    })
}

export const stripHTMLFromString = (string: string | undefined): string => {
    if (!string?.length) return '';

    return string.replace(/<.*?>/g, '');
}

export const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const addTargetBlankToLinks = (htmlText: string) => {
    const element = document.createElement('span')
    element.innerHTML = htmlText;

    _.each(element.getElementsByTagName('a'), (link) => {
        link.setAttribute('target', '_blank');
    })

    return element.innerHTML;
};
