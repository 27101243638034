export enum DateTimeLength {
    FULL = 'full',
    LONG = 'long',
    MEDIUM = 'medium',
    SHORT = 'short',
}

export interface DateTimeFormat {
    dateTime: string;
    date: string;
    time: string;
    year: string;
    month: string;
    day: string;
    hour: string;
    minute: string;
    second: string;
}

export interface DateTimeFormats {
    full: DateTimeFormat;
    long: DateTimeFormat;
    medium: DateTimeFormat;
    short: DateTimeFormat;
}

export interface CalendarSpecification {
    sameDay?: string;
    nextDay?: string;
    lastDay?: string;
    nextWeek?: string;
    lastWeek?: string;
    sameElse?: string;
}

export interface LongDateFormatSpecification {
    LTS: string;
    LT: string;
    L: string;
    LL: string;
    LLL: string;
    LLLL: string;
}

export interface StandaloneFormatSpecification {
    format: string[];
    standalone: string[];
    isFormat?: RegExp;
}

export interface RelativeTimeSpecification {
    future?: string;
    past?: string;
    s?: string;
    ss?: string;
    m?: string;
    mm?: string;
    h?: string;
    hh?: string;
    d?: string;
    dd?: string;
    M?: string;
    MM?: string;
    y?: string;
    yy?: string;
}

export interface WeekSpecification {
    dow: number;
    doy: number;
}

export interface HDateLocale {
    months?: string[] | StandaloneFormatSpecification;
    monthsShort?: string[] | StandaloneFormatSpecification;

    weekdays?: string[] | StandaloneFormatSpecification;
    weekdaysShort?: string[] | StandaloneFormatSpecification;
    weekdaysMin?: string[] | StandaloneFormatSpecification;

    meridiemParse?: RegExp;
    longDateFormat?: LongDateFormatSpecification;
    calendar?: CalendarSpecification;
    relativeTime?: RelativeTimeSpecification;
    invalidDate?: string;
    ordinalParse?: RegExp;
    week?: WeekSpecification;

    meridiem?(hour: number, minute: number, isLower: boolean): string;

    isPM?(input: string): boolean;

    ordinal?(n: number): string;

    // Allow anything: in general any property that is passed as locale spec is
    // put in the locale object so it can be used by locale functions
    [x: string]: any;
}
