import {
    IAteDatabrowserSingleFilter,
    IAteDatabrowserSingleSort,
} from 'components/shared/databrowser/interfaces/databrowser.interface';
import { DICTIONARY } from 'dictionary';

export const MY_COURSES_AND_LP_FILTERS: IAteDatabrowserSingleFilter[] = [
    {
        label: DICTIONARY.notStarted,
        value: 'not_started',
    },
    {
        label: DICTIONARY.inProgress,
        value: 'in_progress',
    },
    {
        label: DICTIONARY.completed,
        value: 'completed',
    },
];

export const MY_COURSES_AND_LP_DEFAULT_SORT = 'enrolled_date_desc';
export const MY_COURSES_AND_LP_SORTS: IAteDatabrowserSingleSort[] = [
    {
        label: DICTIONARY.nameAZ,
        value: 'alpha_asc',
    },
    {
        label: DICTIONARY.nameZA,
        value: 'alpha_desc',
    },
    {
        label: DICTIONARY.codeAZ,
        value: 'code_asc',
    },
    {
        label: DICTIONARY.codeZA,
        value: 'code_desc',
    },
    {
        label: DICTIONARY.newestToOldest,
        value: MY_COURSES_AND_LP_DEFAULT_SORT,
        isActive: true,
    },
    {
        label: DICTIONARY.oldestToNewestEnrollment,
        value: 'enrolled_date_asc',
    },
];

export const FROM_MYCL_QUERY_PARAM = 'from_mycl';
