import { ICommandBus } from "./commandBus.interface";
import { IKernel } from "./kernel.inteface";

export const DCB_FLOW_SDK_ID = 'dcb-flow-sdk';
export const FLOW_SDK_ATE_CONTEXT = "ate";
export const FLOW_SDK_KERNEL_URL = 'https://cdn1.dcbstatic.com/flow/kernels/SDKKernel-v1.0.57.bundle.min.js';
export interface IDSDK {
    kernel(): IKernel;
    getCommandBus(): ICommandBus;
}
