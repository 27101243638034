import React, { useCallback, useState } from 'react';
import { StyledText, Segment } from '@fluentui/react-migration-v0-v9';
import { Spinner } from '@fluentui/react-components';
import './AteDatabrowser.scss';
import AteDatabrowserContent from './databrowser-content/AteDatabrowserContent';
import AteDatabrowserFilter from './databrowser-filter/AteDatabrowserFilter';
import { IAteDatabrowser } from 'components/shared/databrowser/interfaces/databrowser.interface';
import { useWindowSize } from 'helpers/commons.helpers';
import { LGZone, MDZone, XLZone } from 'helpers/viewportBreakpoint.helpers';
import { DICTIONARY } from 'dictionary';
import { AteLocalize } from 'services/translations/AteLocalize';

export default function AteDatabrowser(props: IAteDatabrowser) {
    const showViewAllButton = props.showViewAllButton ?? true;
    const viewAllLabel = props.viewAllLabel ?? AteLocalize.getLocalizedLabel(DICTIONARY.viewAll);
    const [isStretchMode, setIsStretchMode] = useState(false);

    const isMDZone = MDZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    const filters = React.Children.map(props.children, element => {
        if (React.isValidElement(element) &&
            element.type === AteDatabrowserFilter) {
            return element.props.children;
        }
    });

    const content = React.Children.map(props.children, element => {
        if (React.isValidElement(element) &&
            element.type === AteDatabrowserContent) {
            return element.props.children;
        }
    });

    const databrowserFilterContainerRef = useCallback(node => {
        if (node && props.onSetStretchMode) {
            const strechMode = !isMDZone && !isLGZone && !isXLZone;
            setIsStretchMode(strechMode);
            props.onSetStretchMode(strechMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()])

    return (
        <div
            className="databrowser">
            <Segment className="databrowser-top-bar">
                {filters && filters.length &&
                    <div
                        className={`databrowser-filter-container ${isStretchMode ? 'strech-mode' : ''}`}
                        ref={databrowserFilterContainerRef}>
                        {
                            filters.map((filter, index) => (
                                <div
                                    className="filter"
                                    key={index}>
                                    {filter}
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    showViewAllButton &&
                    <div
                        className={`view-all`}>
                        <StyledText
                            weight="semibold"
                            onClick={props.onViewAllClick}
                        >
                            {viewAllLabel}
                        </StyledText>
                    </div>

                }
            </Segment>

            {props.isLoading &&
                <Spinner className={'databrowser-loader'} />
            }
            {!props.isLoading &&
                <div className="databrowser-content">
                    {content}
                </div>
            }
        </div>
    )
}
