import AteCoursePlayer from 'components/widgets/coursePlayer/AteCoursePlayer';
import './CoursePlayerPage.scss';
import { ICoursePlayerProps } from 'components/pages/coursePlayer/interfaces/coursePlayer.interfaces';
import { ID_PLAYER } from 'components/pages/coursePlayer/helpers/coursePlayer.helpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbItems } from 'store/breadcrumb/breadcrumb.slice';
import { PageInfoEnum, PAGES_INFO } from 'routes/pageSettings';
import { useEffect } from 'react';
import { getCourse } from 'api/BackendCalls';
import { getDcbAccessToken, getBaseUrl } from 'store/auth/auth.selectors';
import { useQuery } from 'helpers/location.helpers';
import { IAteBreadcrumbItem } from 'components/shared/breadcrumb/interfaces/breadcrumb.interface';
import { FROM_MYCL_QUERY_PARAM } from 'components/widgets/myCoursesAndLp/helpers/const';
import { getIsTaskModuleMode, getLearningPlanSettings, getStaticCourseListPageSettings } from 'store/app/app.selectors';
import { resetLearningPlanSettings } from 'store/app/app.slice';
import { FROM_SCL_PAGE_QUERY_PARAM } from 'components/widgets/staticCourseList/interfaces/staticCourseList.interface';
import { getWidgetTranslatedLabel } from 'helpers/translations/translations.helpers';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { AteLocalize } from 'services/translations/AteLocalize';
import { getDcbLmsDefaultLanguage } from 'store/bootstrap/boostrap.selector';

export default function CoursePlayerPage(props: ICoursePlayerProps) {
    const { id, autoplay } = props.location!.state || props.match!.params;
    const accessToken = useSelector(getDcbAccessToken);
    const baseUrl = useSelector(getBaseUrl);
    const isTaskModuleMode = useSelector(getIsTaskModuleMode);
    const learningPlanSettings = useSelector(getLearningPlanSettings);
    const staticCourseListPageSettings = useSelector(getStaticCourseListPageSettings);
    const userLanguage = useSelector(getAppCurrentLanguage);
    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);

    const queryParams = useQuery();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isTaskModuleMode) {
            setBreadcrumb();
        }

        return () => {
            if (learningPlanSettings) {
                dispatch(resetLearningPlanSettings(undefined));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setBreadcrumb = () => {
        getCourse({ accessToken, baseUrl }, id)
            .subscribe(courseInfo => {
                const previousHistory: IAteBreadcrumbItem[] = [
                    PAGES_INFO[PageInfoEnum.DASHBOARD]
                ];

                if (queryParams.get(FROM_MYCL_QUERY_PARAM) === 'true') {
                    previousHistory.push(PAGES_INFO[PageInfoEnum.MY_COURSES_AND_LP]);
                }

                if (queryParams.get(FROM_SCL_PAGE_QUERY_PARAM) === 'true') {

                    const title = staticCourseListPageSettings?.title ?
                        getWidgetTranslatedLabel(staticCourseListPageSettings.title, userLanguage, dcbLmsDefaultPlatformLanguage) :
                        AteLocalize.getLocalizedLabel(PAGES_INFO[PageInfoEnum.STATIC_COURSE_LIST].title);

                    previousHistory.push({
                        path: PAGES_INFO[PageInfoEnum.STATIC_COURSE_LIST].path,
                        title
                    });
                }

                if (learningPlanSettings) {
                    previousHistory.push({
                        path: `/learning-plan/${learningPlanSettings.id}?${FROM_MYCL_QUERY_PARAM}=${queryParams.get(FROM_MYCL_QUERY_PARAM)}`,
                        title: learningPlanSettings.name
                    });
                }

                dispatch(
                    updateBreadcrumbItems({
                        items: [
                            ...previousHistory,
                            {
                                path: `/course-player/${courseInfo.id}`,
                                title: courseInfo.name
                            }
                        ]
                    })
                );
            })
    }

    return (
        <div
            className={'course-player-container'}
        >
            <AteCoursePlayer
                courseId={id}
                idPlayer={ID_PLAYER}
                autoplay={autoplay}
            />
        </div>
    );
}
