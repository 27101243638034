import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { MSTeamsThemes } from 'components/App';
import { IAteMyCoursesAndLpWidget, IMyCourseAndLpFiltersSettings } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface';
import { IAteStaticCourseListWidget } from 'components/widgets/staticCourseList/interfaces/staticCourseList.interface';
import { App } from 'intefaces/App';
import { ILearningPlan } from 'intefaces/learningPlan.interface';
import { PageInfoEnum, PAGES_INFO } from 'routes/pageSettings';

const appInitialState: App = {
    isFlowSDKReady: false,
    isTaskModuleMode: false,
    isCourseSharingEnabled: false,
    originalPath: PAGES_INFO[PageInfoEnum.DASHBOARD].path,
    myCourseAndLpWidgetSettings: [],
};

const slice: Slice<App> = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: {
        updateMyCoursesAndLearnignPlanUUIDActivated: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...state,
                myCLPActivatedUUID: action.payload
            }
        },
        resetMyCoursesAndLpWidgetSettings: (state, action: PayloadAction<[]>) => {
            return {
                ...state,
                myCourseAndLpWidgetSettings: action.payload
            }
        },
        updateMyCoursesAndLpWidgetSettings: (state, action: PayloadAction<IAteMyCoursesAndLpWidget>) => {
            const { UUID } = action.payload;

            const existingItem = state.myCourseAndLpWidgetSettings?.findIndex(item => item.UUID === UUID);

            if (existingItem !== -1) {
                state.myCourseAndLpWidgetSettings[existingItem] = {
                    ...state.myCourseAndLpWidgetSettings[existingItem],
                    ...action.payload
                }
            } else {
                state.myCourseAndLpWidgetSettings?.push({ ...action.payload });
            }
        },
        updateMyCoursesAndLpFilters: (state, action: PayloadAction<{ UUID: string, filters: IMyCourseAndLpFiltersSettings }>) => {
            const existingItem = state.myCourseAndLpWidgetSettings?.find(item => item.UUID === action.payload.UUID);

            if (existingItem) {
                existingItem.filtersSettings = { ...existingItem.filtersSettings, ...action.payload.filters };
            }
        },
        updateLearningPlanSettings: (state, action: PayloadAction<ILearningPlan>) => {
            return {
                ...state,
                learningPlanSettings: { ...action.payload },
            };
        },
        resetLearningPlanSettings: (state, action: PayloadAction<undefined>) => {
            return {
                ...state,
                learningPlanSettings: action.payload
            };
        },
        resetAppReduder: () => {
            return appInitialState;
        },
        setMsTeamsTheme: (state, action: PayloadAction<MSTeamsThemes>) => {
            return {
                ...state,
                msTeamsThemeSelected: action.payload
            }
        },
        updateFlowSdkStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isFlowSDKReady: action.payload
            }
        },
        updateOriginalPath: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                originalPath: action.payload
            }
        },
        updateTaskModuleMode: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isTaskModuleMode: action.payload
            }
        },
        updateCourseSharingStatus: (state, action: PayloadAction<boolean>) => {
            //Remember to disable Sharing in MOBILE APP like "/pages/login/LoginPage.tsx:96"
            return {
                ...state,
                isCourseSharingEnabled: action.payload
            }
        },
        updateStaticCourseListPage: (state, action: PayloadAction<IAteStaticCourseListWidget>) => {
            return {
                ...state,
                staticCourseListPageSettings: action.payload
            }
        }
    },
});

export const {
    updateMyCoursesAndLpWidgetSettings,
    resetMyCoursesAndLpWidgetSettings,
    resetAppReduder,
    updateLearningPlanSettings,
    resetLearningPlanSettings,
    setMsTeamsTheme,
    updateFlowSdkStatus,
    updateOriginalPath,
    updateTaskModuleMode,
    updateCourseSharingStatus,
    updateStaticCourseListPage,
    updateMyCoursesAndLpFilters,
    updateMyCoursesAndLearnignPlanUUIDActivated
} = slice.actions;
export default slice.reducer;
