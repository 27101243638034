// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as moment from 'moment';
import { Duration } from 'moment';

export class HDuration {
    private duration: Duration = null;

    /**
     * Creates a new HDuration instance.
     * @param length The length (expressed in milliseconds or as a parsable string) of the time interval.
     * @returns A new HDuration instance.
     */
    constructor(length: number | string) {
        this.duration = moment.duration(length);
    }

    /**
     * Formats the HDuration as a human-readable string.
     * No prefixes/suffixes that express the interval as in the future or in the past are included.
     * Example: "Three years"
     * @returns The human-readable duration.
     */
    toWords(): string {
        return this.duration.humanize();
    }

    /**
     * Formats the HDuration as a human-readable string, with prefixes/suffixes that express the interval as in the future.
     * Example: "In three years"
     * @returns The human-readable duration.
     */
    toWordsFuture(): string {
        const ms = Math.abs(this.duration.asMilliseconds());

        return moment.duration(ms)
            .humanize(true);
    }

    /**
     * Formats the HDuration as a human-readable string, with prefixes/suffixes that express the interval as in the past.
     * Example: "Three years ago"
     * @returns The human-readable duration.
     */
    toWordsPast(): string {
        const ms = -Math.abs(this.duration.asMilliseconds());

        return moment.duration(ms)
            .humanize(true);
    }

    /**
     * Gets the milliseconds.
     * @returns The milliseconds.
     */
    asMilliseconds(): number {
        return this.duration.asMilliseconds();
    }

    /**
     * Gets the seconds.
     * @returns The seconds.
     */
    asSeconds(): number {
        return this.duration.asSeconds();
    }

    /**
     * Gets the minutes.
     * @returns The minutes.
     */
    asMinutes(): number {
        return this.duration.asMinutes();
    }

    /**
     * Gets the hours.
     * @returns The hours.
     */
    asHours(): number {
        return this.duration.asHours();
    }

    /**
     * Gets the days.
     * @returns The days.
     */
    asDays(): number {
        return this.duration.asDays();
    }

    /**
     * Gets the weeks.
     * @returns The weeks.
     */
    asWeeks(): number {
        return this.duration.asWeeks();
    }

    /**
     * Gets the months.
     * @returns The months.
     */
    asMonths(): number {
        return this.duration.asMonths();
    }

    /**
     * Gets the years.
     * @returns The years.
     */
    asYears(): number {
        return this.duration.asYears();
    }
}
