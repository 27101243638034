import { IAteIllustration } from 'components/shared/illustration/interfaces/illustration.interfaces';
import React from 'react';
import './AteIllustration.scss'
import { DCB_UI_SVG_ILLUSTRATIONS } from 'components/shared/illustration/helpers/illustrations';

export function AteIllustration(props: IAteIllustration) {
    return (
        <div className={`ate-illustration ate-illustration-size-${props.size}`}>
            <span dangerouslySetInnerHTML={{__html: DCB_UI_SVG_ILLUSTRATIONS[props.name]}} />
        </div>
    );
}
