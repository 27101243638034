import { useQuery } from 'helpers/location.helpers';
import { TASK_MODULE_MODE_PARAM } from 'helpers/sharing.helpers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { updateTaskModuleMode, updateOriginalPath } from 'store/app/app.slice';
import { getIsLogged } from 'store/auth/auth.selectors';
import { PageInfoEnum, PAGES_INFO } from './pageSettings';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import APP_ROUTES from './routes.config';

export default function Routes() {
    const location = useLocation();
    const queryParams = useQuery();

    const dispatch = useDispatch();

    const isLogged = useSelector(getIsLogged);

    useEffect(() => {
        if (queryParams) {
            if (queryParams.get(TASK_MODULE_MODE_PARAM) === 'true') {
                dispatch(updateTaskModuleMode(true));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (location.pathname && location.pathname !== PAGES_INFO[PageInfoEnum.LOGIN].path) {
            dispatch(updateOriginalPath(location.pathname));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged])

    return (
        <Switch>
            {
                APP_ROUTES.map((route, index) =>
                    route.authRequired ?
                        <PrivateRoute
                            path={route.path}
                            component={route.component}
                            key={index}
                        /> :
                        <PublicRoute
                            path={route.path}
                            component={route.component}
                            restricted={route.restricted}
                            key={index}
                        />
                )
            }
        </Switch>
    );
}
