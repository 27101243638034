import React, { useEffect, useRef, useState } from "react";
import { CheckmarkRegular, ArrowSortRegular } from "@fluentui/react-icons";
import { Segment } from "@fluentui/react-migration-v0-v9";
import AteButtonRaised from "components/shared/buttons/button-raised/AteButtonRaised";
import './AteDatabrowserDropdownSort.scss';
import { Subject } from "rxjs";
import { IAteDatabrowserDropdownSort, IAteDatabrowserSingleSort } from "components/shared/databrowser/interfaces/databrowser.interface";
import { isClickingOutsideElement } from "helpers/commons.helpers";
import { AteLocalize } from "services/translations/AteLocalize";

export default function AteDatabrowserDropdownSort(props: IAteDatabrowserDropdownSort) {
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [sorts, setSorts] = useState(props.sorts);
    const sortSelected = new Subject<IAteDatabrowserSingleSort>();
    const firstUpdate = useRef(true);
    const panelRef = useRef(null);

    isClickingOutsideElement(panelRef)
        .subscribe(() => setDropdownOpened(false));

    sortSelected
        .asObservable()
        .subscribe(sort => {
            setDropdownOpened(false);
            props.onSortChange(sort);
        })

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            sortSelected.next(getSortActive());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorts])

    const getSortActive = () => {
        return sorts.find(s => s.isActive === true) ?? sorts[0];
    }

    const sortClicked = (sort: IAteDatabrowserSingleSort, sortIndex: number) => {
        const sortsCopy = sorts.map(s => Object.assign({}, { ...s, isActive: false }));
        const sortToUpdate = sortsCopy.find((s, index) => s.value === sort.value && index === sortIndex);

        if (sortToUpdate) {
            sortToUpdate.isActive = true;
        }

        setSorts(sortsCopy);
    }

    return (
        <>
            <div
                className={`databrowser-sort-panel ${dropdownOpened ? 'active' : ''}`}
                ref={panelRef}
            >
                <div
                    className="button-container">
                    <AteButtonRaised
                        isActive={dropdownOpened}
                        icon={<ArrowSortRegular />}
                        content={!props.isStretchMode ? AteLocalize.getLocalizedLabel(getSortActive().label) : ''}
                        onClick={() => { setDropdownOpened(!dropdownOpened) }}
                    />
                </div>

                <Segment
                    className="sort-list">

                    {sorts.map((sort, index) => (
                        <div
                            className="single-sort"
                            key={index}
                            onClick={() => sortClicked(sort, index)}>
                            <span
                                className="label">
                                {AteLocalize.getLocalizedLabel(sort.label)}
                            </span>
                            {sort.value === getSortActive().value ?
                                <CheckmarkRegular /> :
                                ''}
                        </div>
                    ))}

                </Segment>
            </div>
        </>
    )
}
