import { ArrowLeftRegular, SearchRegular, DismissRegular } from '@fluentui/react-icons';
import AteButtonRaised from 'components/shared/buttons/button-raised/AteButtonRaised';
import { IAteDatabrowserSearch } from 'components/shared/databrowser/interfaces/databrowser.interface';
import { useWindowSize } from 'helpers/commons.helpers';
import { LGZone, XLZone, MDZone } from 'helpers/viewportBreakpoint.helpers';
import React, { useEffect, useState } from 'react';
import './AteDatabrowserSearch.scss';
import { DICTIONARY } from 'dictionary';
import { AteLocalize } from 'services/translations/AteLocalize';
import { Input, InputProps } from '@fluentui/react-components';

export default function AteDatabrowserSearch(props: IAteDatabrowserSearch) {
    const textSearchPlaceholder = props.textSearchPlaceholder ?? AteLocalize.getLocalizedLabel(DICTIONARY.find);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isFullMode, setIsFullMode] = useState(false);

    const isMDZone = MDZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    useEffect(() => {
        if (props.valueToSearch) {
            setSearchValue(props.valueToSearch)
        }
    }, [props.valueToSearch])

    useEffect(() => {
        if (isMDZone || isLGZone || isXLZone) {
            setIsFullMode(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()])

    useEffect(() => {
        if (props.onFullModeChange) {
            props.onFullModeChange(isFullMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullMode])

    const handleTextSearchSubmit = (event: any) => {
        if (event.key === 'Enter' || event.type === 'click') {
            return props.onTextSearchSubmit(searchValue);
        }
    }

    const onChange: InputProps["onChange"] = (ev, data) => {
        setSearchValue(data?.value || '');
    };

    const onDismissClicked = () => {
        setSearchValue('');
        props.onTextSearchSubmit('');
    }

    return (
        <div
            className={`databrowser-search-container ${isFullMode ? 'full-mode' : ''}`}>
            {
                (props.isStretchMode && !isFullMode) &&
                <AteButtonRaised
                    onClick={() => setIsFullMode(true)}
                    icon={<SearchRegular />}
                />
            }
            {
                <div className='input-container'>
                    {
                        (props.isStretchMode && isFullMode) &&
                        <div className='back-button'>
                            <AteButtonRaised
                                icon={<ArrowLeftRegular />}
                                iconPosition="before"
                                onClick={() => setIsFullMode(false)}
                            />
                        </div>
                    }
                    {
                        (!props.isStretchMode || isFullMode) &&
                        <Input
                            className='input'
                            contentAfter={searchValue ?
                                <DismissRegular className='search-button' onClick={onDismissClicked} /> :
                                <SearchRegular className='search-button' onClick={handleTextSearchSubmit} />
                            }
                            placeholder={textSearchPlaceholder}
                            onKeyDown={handleTextSearchSubmit}
                            onChange={onChange}
                            value={searchValue}
                        />
                    }
                </div>
            }
        </div >
    )
}
