import { Flex } from "@fluentui/react-migration-v0-v9";
import AteDatabrowserDropdownFilter from "components/shared/databrowser/databrowser-filter-dropdown/AteDatabrowserDropdownFilter";
import AteDatabrowserSearch from "components/shared/databrowser/databrowser-search/AteDatabrowserSearch";
import AteDatabrowserDropdownSort from "components/shared/databrowser/databrowser-sort-dropdown/AteDatabrowserDropdownSort";
import { IAteDatabrowserSingleFilter, IAteDatabrowserSingleSort } from "components/shared/databrowser/interfaces/databrowser.interface";
import { MY_COURSES_AND_LP_FILTERS, MY_COURSES_AND_LP_SORTS } from "components/widgets/myCoursesAndLp/helpers/const";
import { MyCoursesAndLpCourseStatusEnum } from "components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMyCLPById, getUUIDMyCLPActivated } from "store/app/app.selectors";
import './AteBreadcrumbFilters.scss';
import { useDispatch } from "react-redux";
import { updateMyCoursesAndLpFilters } from "store/app/app.slice";
import { useWindowSize } from "helpers/commons.helpers";
import { MDZone, LGZone, XLZone } from "helpers/viewportBreakpoint.helpers";

export default function AteBreadcrumbFilters() {
    const dispatch = useDispatch();

    const isMDZone = MDZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    const uuidActivated = useSelector(getUUIDMyCLPActivated);
    const settings = useSelector(state => getMyCLPById(state, uuidActivated));

    const [isStretchMode, setIsStretchMode] = useState(false);
    const [isSearchFullModeActive, setIsSearchFullModeActive] = useState(false);

    useEffect(() => {
        setIsStretchMode(!isMDZone && !isLGZone && !isXLZone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()])

    const searchChanged = (search?: string) => {
        dispatch(updateMyCoursesAndLpFilters({ UUID: settings?.UUID, filters: { search } }));
    };

    const filtersChanged = (filters: IAteDatabrowserSingleFilter[]) => {
        const filtersSelected = filters.map(f => f.value);
        dispatch(updateMyCoursesAndLpFilters({ UUID: settings?.UUID, filters: { filters: filtersSelected } }));
    };

    const sortChanged = (sortSelected: IAteDatabrowserSingleSort) => {
        dispatch(updateMyCoursesAndLpFilters({
            UUID: settings?.UUID,
            filters: {
                sort: sortSelected.value,
            }
        }));
    };

    return (
        settings ?
            < Flex
                vAlign="center"
                className={`filters-container ${isSearchFullModeActive ? 'search-exploded' : ''} ${isStretchMode ? 'stretch-mode' : ''}`
                }
            >
                {
                    settings.course_status === MyCoursesAndLpCourseStatusEnum.ALL &&
                    <AteDatabrowserDropdownFilter
                        isStretchMode={isStretchMode}
                        onFiltersChange={filtersChanged}
                        filters={MY_COURSES_AND_LP_FILTERS}
                    />
                }
                < AteDatabrowserDropdownSort
                    isStretchMode={isStretchMode}
                    sorts={MY_COURSES_AND_LP_SORTS}
                    onSortChange={sortChanged}
                />
                <AteDatabrowserSearch
                    isStretchMode={isStretchMode}
                    valueToSearch={settings?.filtersSettings?.search}
                    onTextSearchSubmit={searchChanged}
                    onFullModeChange={(isActive: boolean) => setIsSearchFullModeActive(isActive)}
                />
            </Flex > : <></>
    )
}
